import React from 'react';
import { Button, DropdownButton, InputGroup } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Helpers/authContext';
import Searchbar from './Searchbar';


const TopNav = () => {
  const { logoutUser } = useAuth();
  const navigate = useNavigate()

  const handleLogout = () => {
    logoutUser();
    navigate('/');
  }

  return (
    <nav className="justify-content-between sb-topnav navbar navbar-expand navbar-dark bg-dark ">
      <a className="navbar-brand ps-3">JEST SİGORTA</a>
      <div className='d-flex flex-row align-middle justify-content-around align-items-center'>
        <button
          className="btn btn-link btn-sm order-1 order-0 me-2 me-md-4"
          id="sidebarToggle"
          onClick={e => {e.preventDefault(); document.querySelector('body')?.classList.toggle('sb-sidenav-toggled')}}  
        ><i className="fas fa-bars"></i></button>
        <form className="d-none d-md-inline-block form-inline">
          <div className="input-group">
            <Searchbar />
            <button className="btn btn-primary" id="btnNavbarSearch" type="button"><i className="fas fa-search"></i></button>
          </div>
        </form>
        <InputGroup className='d-inline-block d-md-none mx-1'>
          <DropdownButton variant='outline-secondary' style={{color:'rgb(255,255,255,0.5)'}} title={<i className="fas fa-search" />}>
            <div style={{'width': '90vw'}} className="py-2 px-1">
              <Searchbar />
            </div>
          </DropdownButton>
        </InputGroup>
        <ul className="navbar-nav ms-auto ms-md-0 mx-1">
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-user fa-fw"></i></a>
            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
              <li><Button className="dropdown-item" onClick={handleLogout}>Çıkış Yap</Button></li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>


  )

}


export default TopNav