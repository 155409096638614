import React, { useEffect, useState } from 'react';
import { Accordion, Button, Modal, Table, Form, Breadcrumb } from 'react-bootstrap';
import { useLocation, useParams, useSearchParams, Link, useNavigate } from 'react-router-dom';
import { useGetOneContactQuery, useRemoveContactMutation } from '../generated/graphql';
import graphqlRequestClient from '../Helpers/graphql-client';
import { format } from 'date-fns'
import { tr } from 'date-fns/locale'
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';

const ContactsView = () => {
  const urlParams = useParams();
  const navigate = useNavigate();
  const id = Number(urlParams.id!)
  const client = useQueryClient();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalName, setModalName] = useState('');
  useEffect(() => {
    setModalName('');
  }, [showDeleteModal])

  const { data } = useGetOneContactQuery(graphqlRequestClient, { id }, {});
  const { mutate: removeContactMut } = useRemoveContactMutation(graphqlRequestClient, {
    onSuccess: () => {
      navigate('/app/contacts')
      client.invalidateQueries('GetOneContact');
      toast.success('Müşteri başarıyla silindi.')
    }
  }, {})

  const handleNewProperty = () => {
    navigate('/app/properties/new', { state: { contact: data?.contact } })
  }

  const handleUpdateContact = () => {
    navigate('/app/contacts/update/' + data?.contact.id, { state: { contact: data?.contact } })
  }

  const handleNewInsurance = (car: any) => {
    navigate('/app/car-insurances/new', { state: { car: car } });
  }

  const handleViewCar = (car: any) => {
    // TODO: when car gets too complex, remove passing state and fetch the car in the CarsView
    navigate('/app/properties/cars/' + car.id);
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to={'/app/contacts'}>Müşteriler</Link></Breadcrumb.Item>
        <Breadcrumb.Item active>{data?.contact.name}</Breadcrumb.Item>
      </Breadcrumb>
      <h2 className='display-2 text-center text-md-start mt-3'> {data?.contact.name} </h2>
      <p className='mt-3'> T.C. : {data?.contact.turkish_id} </p>
      <p> Telefon : {data?.contact.phone} </p>
      <p> Doğum Tarihi: {data?.contact.birthdate ? format(new Date(data?.contact.birthdate), 'dd LLLL yyyy', { locale: tr }) : '-'} </p>
      <Accordion alwaysOpen className="my-5">
        {data?.contact.cars?.map(car => {
          return (
            <Accordion.Item eventKey={car.id} key={car.id}>
              <Accordion.Header> Araba - {car.plate}</Accordion.Header>
              <Accordion.Body>
                <Button variant="primary" onClick={e => handleViewCar(car)}> Aracı Görüntüle </Button>
                <Button variant="info" onClick={e => handleNewInsurance(car)}> Sigorta Ekle </Button>
                <br />
                <br />
                {car.carInsurances[0] &&
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th> Sigorta Adı </th>
                        <th> Durum </th>
                        <th> Başlangıç Tarihi </th>
                        <th> Bitiş Tarihi </th>
                      </tr>
                    </thead>
                    <tbody>
                      {car.carInsurances?.map(insurance => {
                        return (
                          <tr key={insurance.id}>
                            <td><Link to={'/app/car-insurances/' + insurance.id}>{insurance.title}</Link></td>
                            <td>{insurance.state == 'US' ? 'Bizde' : insurance.state == 'UNKNOWN' ? 'Bilgi yok' : 'Rakipte'}</td>
                            <td>{insurance.startTime ? format(new Date(insurance.startTime), 'dd LLLL yyyy', { locale: tr }) : '-'}</td>
                            <td>{insurance.endTime ? format(new Date(insurance.endTime), 'dd LLLL yyyy', { locale: tr }) : '-'}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                }

              </Accordion.Body>
            </Accordion.Item>
          )
        })}
      </Accordion>

      <div className='d-grid gap-2 d-md-block'>
        <Button className='py-3 py-md-2' variant="info" onClick={handleNewProperty}> Varlık Ekle </Button>
        <Button className='py-3 py-md-2' variant="warning" onClick={handleUpdateContact}> Müşteriyi Düzenle </Button>
        <Button className='py-3 py-md-2' variant="danger" onClick={() => setShowDeleteModal(true)}> Müşteriyi Sil </Button>
      </div>

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
      >
        <Modal.Header>
          <Modal.Title> Müşteriyi Sil </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p> <b>{data?.contact.name + ' ' + data?.contact.surname.toLocaleUpperCase('tr')} </b> adlı müşteriyi silmek için müşterinin adını ve soyadını KALIN YAZILDIĞ ŞEKİLDE (büyük küçük harf duyarlı)
            yazınız.
          </p>
          <p> Silme işlemi bu müşteriye ait bütün varlıkları ve o varlıklara ait bütün sigorta belgelerini kayıtlardan siler. Bu işlem geri alınamaz.</p>
          <Form.Control type="text" value={modalName} onChange={e => setModalName(e.target.value)} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}> İptal </Button>
          <Button variant="danger" disabled={data?.contact.name + ' ' + data?.contact.surname.toLocaleUpperCase('tr') !== modalName}
            onClick={() => removeContactMut({ input: parseInt(data!.contact.id) })}
          > Müşteriyi Sil </Button>
        </Modal.Footer>
      </Modal>
    </>
  )


}

export default ContactsView;