import React from 'react';
import { ListGroup, Spinner } from 'react-bootstrap';
import { useGetAllEventsQuery } from '../generated/graphql';
import graphqlRequestClient from '../Helpers/graphql-client';

const Dashboard = () => {


  const { data, isLoading } = useGetAllEventsQuery(graphqlRequestClient, {

  })


  return (
    <>
      <h1>Anasayfa</h1>

      <h2>Bugünkü Etkinlikleriniz</h2>
      <ListGroup>
        {data?.events.filter(event => new Date(event.endTime).toDateString() == new Date().toDateString()).map(customEvent => {
          return (
            <ListGroup.Item key={customEvent.id}>
              {customEvent.title} -- {customEvent.note}
            </ListGroup.Item>
          )
        })}
        {isLoading && <Spinner animation="border" />}
      </ListGroup>
      <br />
      <br />
      <h2>Bugün Yenilenmesi Gereken Sigortalar</h2>
      <ListGroup>
        {data?.carInsurances.filter(ins => new Date(ins.endTime).toDateString() == new Date().toDateString()).map(ins => {
          return (<ListGroup.Item key={ins.id}>{ins.car.plate}  {ins.title} -- {ins.car.contact.name} {ins.car.contact.surname}</ListGroup.Item>)
        })}
      </ListGroup>
    </>
  )

}


export default Dashboard