import React from 'react';
import { Outlet } from 'react-router-dom';



const PropertiesApp = () => {


  return <Outlet />

}


export default PropertiesApp;