import React, { FormEvent, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetOneUserQuery, useUpdateUserMutation } from '../generated/graphql';
import graphqlRequestClient from '../Helpers/graphql-client';


const UserUpdate = () => {

  const params = useParams()
  const userId = params.id
  const navigate = useNavigate()
  const { data } = useGetOneUserQuery(graphqlRequestClient, { id: Number(userId) }, {

  })

  const [state, setState] = useState({
    username: data?.user.username,
    password1: '',
    password2: '',
  })

  const { mutate } = useUpdateUserMutation(graphqlRequestClient, {
    onSettled: () => {
      toast.success("Kullanıcı başarıyla güncellendi.")
      navigate('/app/users/'+userId)
    }
  }) 

  



  const handleSubmit = (e:FormEvent) => {
    e.preventDefault();
    if (state.password1 !== state.password2) {
      return
    }

    if (state.password1 == '') {
      
      mutate({
        id: Number(userId),
        input: {
          username: {
            set: state.username
          }
        }
      })

    } else {

      mutate({
        id: Number(userId),
        input: {
          username: {
            set: state.username
          },
          password: {
            set: state.password1
          }
        }
      })

    }
  }
  

  return (<>
    <h1> Düzenle: {data?.user.username} </h1>

    <Form onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Label>Kullanıcı Adı</Form.Label>
        <Form.Control type="text" value={state.username} onChange={e=>setState({...state, username: e.target.value})} />
      </Form.Group>

      <Form.Group>
        <Form.Label>Şifre</Form.Label>
        <Form.Control type="password" value={state.password1} onChange={e=>setState({...state, password1: e.target.value})} />
      </Form.Group>

      <Form.Group>
        <Form.Label>Şifre Tekrar</Form.Label>
        <Form.Control type="password" value={state.password2} onChange={e=>setState({...state, password2: e.target.value})} />
      </Form.Group>

      <Button variant='primary' type="submit" disabled={state.password1 !== state.password2}> Kullanıcıyı Güncelle </Button>
      
    </Form>

  </>)

}

export default UserUpdate