import React from 'react';

import { Outlet } from 'react-router-dom';



const ContactsApp = () => {

  return (
    <div>
      <Outlet />
    </div>
  )

}


export default ContactsApp