import { format } from 'date-fns';
import React, { FormEvent, useState } from 'react';
import { Breadcrumb, Button, Form } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUpdateContactMutation } from '../generated/graphql';
import graphqlRequestClient from '../Helpers/graphql-client';

const ContactsUpdate = () => {

  const { state }: any = useLocation();
  const navigate = useNavigate();

  const [data, setData] = useState({
    name: state.contact.name,
    surname: state.contact.surname,
    turkish_id: state.contact.turkish_id,
    birthdate: format(new Date(state.contact.birthdate), 'yyyy-MM-dd'),
    phone: state.contact.phone,
  })

  const { mutate } = useUpdateContactMutation(graphqlRequestClient, {
    onSuccess: () => {
      navigate('/app/contacts/' + state.contact.id)
      toast.success('Müşteri başarıyla güncellendi.')
    }
  })

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    mutate({
      id: parseInt(state.contact.id),
      input: {
        name: {
          set: data.name
        },
        surname: {
          set: data.surname
        },
        turkish_id: {
          set: data.turkish_id
        },
        phone: {
          set: data.phone
        },
        birthdate: {
          set: data.birthdate
        }
      }
    })
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to={'/app/contacts'}>Müşteriler</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to={'/app/contacts/' + state.contact.id}>{state.contact.name + ' ' + state.contact.surname}</Link></Breadcrumb.Item>
        <Breadcrumb.Item active>Düzenle</Breadcrumb.Item>
      </Breadcrumb>
      <p>Müşteriyi Düzenle: </p>
      <h1 className='display-2 text-center text-md-start'> {state.contact.name + ' ' + state.contact.surname} </h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Ad</Form.Label>
          <Form.Control type="text" size='lg' value={data.name} onChange={e => setData({ ...data, name: e.target.value })} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Soyad</Form.Label>
          <Form.Control type="text" size='lg' value={data.surname} onChange={e => setData({ ...data, surname: e.target.value })} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Doğum Tarihi</Form.Label>
          <Form.Control type="date" size='lg' value={data.birthdate} onChange={e => setData({ ...data, birthdate: e.target.value })} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Telefon</Form.Label>
          <Form.Control type="text" size='lg' value={data.phone} onChange={e => setData({ ...data, phone: e.target.value })} />
        </Form.Group>
        <Form.Group>
          <Form.Label>T.C.</Form.Label>
          <Form.Control type="text" size='lg' value={data.turkish_id} onChange={e => setData({ ...data, turkish_id: e.target.value })} />
        </Form.Group>
        <div className='d-grid gap-3 d-md-block mt-3'>
          <Button type="submit" className='py-3 py-md-2' variant="success"> Güncelle </Button>
        </div>
      </Form>
    </>
  )

}

export default ContactsUpdate;