import React, {useState, createContext, useContext} from 'react';


const UserContext = createContext(); // { user, loginUser(), logoutUser() }

export const useAuth = () => {
  return useContext(UserContext);
}

export const AuthProvider = ({ children }) => {
  /**
   * user = {
   *  id, 
   *  username,
   *  token,
   *  ...
   * }
   */
  const [user, setUser] = useState({});
  const [loggedIn, setLoggedIn] = useState(false)

  const loginUser = (user) => {
    setLoggedIn(true);
    setUser(user);
  }

  const logoutUser = () => {
    setLoggedIn(false);
    setUser({});
  }

  return (
    <UserContext.Provider value={{loggedIn, user, loginUser, logoutUser}}>
      {children}
    </UserContext.Provider>
  )
}