import React, { useEffect, useState } from 'react';
import { Breadcrumb, Form } from 'react-bootstrap';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import Select from 'react-select'
import CarsNew from './Cars/CarsNew';
import EstatesNew from './Estates/EstatesNew';


const options = [
  {value: 'car', label: 'Araba'},
  {value: 'estate', label: 'Emlak'},
]


const PropertiesNew = () => {
  const { state } : any = useLocation();
  const [contact, setContact] = useState<any>();
  const [option, setOption] = useState(options[0]);
  const navigate = useNavigate();
  
  useEffect(() => {
    setContact(state?.contact);
  }, [])
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to={'/app/contacts'}>Müşteriler</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to={'/app/contacts/' + contact?.id}>{contact?.name + ' ' + contact?.surname}</Link></Breadcrumb.Item>
        <Breadcrumb.Item active> Yeni Varlık </Breadcrumb.Item>
      </Breadcrumb>
      <h1 className='display-1 text-center text-md-start my-2'> <b>{contact?.name + ' ' + contact?.surname}</b> için Yeni Varlık </h1>
      <br />
      <Form.Group>
        <Form.Label> Varlık Sahibi: </Form.Label>
        <Form.Control size='lg' type="text" value={contact?.name + " " + contact?.surname}  disabled/>
      </Form.Group>
      <Form.Group className='mt-2'>
        <Form.Label> Varlık Tipi: </Form.Label>
        <Select options={options}  defaultValue={options[0]} onChange={(option) => setOption(option!)}/>
      </Form.Group>
      <br />
      {
        (() => {
          switch(option.value) {
            case 'car': {
              return <CarsNew contact={contact}/>
            }
            case 'estate': {
              return <EstatesNew />
            }
            default:{
              return <h1>Default</h1>
            }
          }
        })()
      }
    </>
  )

}


export default PropertiesNew;