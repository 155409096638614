import React from 'react';

const EstatesNew = () => {


  


  return (
  <>
    <h1>EstatesNew</h1>  
  
  </>)
}


export default EstatesNew;