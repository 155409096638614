import React from 'react';
import { Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useGetOneUserQuery } from '../generated/graphql';
import graphqlRequestClient from '../Helpers/graphql-client';


const UserView = () => {

  const urlParams = useParams();
  const userId = urlParams.id

  const { data } = useGetOneUserQuery(graphqlRequestClient, {id: Number(userId)}, {

  })
  


  return (
  <>
    <h1> Kullanıcı: {data?.user.username} </h1>
    <Link to={"/app/users/update/"+userId} className='btn btn-info'> Düzenle </Link>
  </>)

}

export default UserView;