import React, { FormEvent, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCreateUserMutation } from '../generated/graphql';
import graphqlRequestClient from '../Helpers/graphql-client';


const UserNew = () => {

  const [state, setState] = useState({
    username: '',
    password1: '',
    password2: ''
  })

  const navigate = useNavigate();

  const { mutate } = useCreateUserMutation(graphqlRequestClient, {
    onSettled: () => {
      toast.success('Kullanıcı başarıyla oluşturuldu.');
      navigate('/app/settings');
    }
  })

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if ( state.password1 !== state.password2 ) {
      return
    }

    mutate({
      input: {
        username: state.username,
        password: state.password1
      }
    })
  }

  return(
  <>
    <h1> Yeni Kullanıcı Oluştur </h1>
  
    <Form onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Label>Kullanıcı Adı</Form.Label>
        <Form.Control type="text" value={state.username} onChange={e=>setState({...state, username: e.target.value})} />
      </Form.Group>

      <Form.Group>
        <Form.Label>Şifre</Form.Label>
        <Form.Control type="password" value={state.password1} onChange={e=>setState({...state, password1: e.target.value})} />
      </Form.Group>

      <Form.Group>
        <Form.Label>Şifre (Tekrar)</Form.Label>
        <Form.Control type="password" value={state.password2} onChange={e=>setState({...state, password2: e.target.value})} />
      </Form.Group>

      <Button variant="primary" type="submit" disabled={(state.username == '') || (state.password1 != state.password2) || (state.password1 == '')}> Kullanıcı Oluştur </Button>

    </Form>
    
  </>)

}

export default UserNew