import React from 'react';
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useGetUsersQuery } from '../generated/graphql';
import { useAuth } from '../Helpers/authContext';
import graphqlRequestClient from '../Helpers/graphql-client';

const Settings = () => {

  const { user } = useAuth();


  const { data, isLoading } = useGetUsersQuery(graphqlRequestClient, {

  });


  return (
    <>
      <h1> Ayarlar </h1>
      <Form.Label>Ofis Adı</Form.Label>
      <Form.Control value={user.officename} disabled />

      <h2 className="pt-5">Kullanıcılar</h2>
      <Link className='btn btn-info' to={'/app/users/new'}> Kullanıcı Ekle </Link>
      <ul className='mt-1'>
        { !isLoading && data?.users.map(user => {
          return (
            <li className='lead' key={user.id}><Link to={"/app/users/"+user.id}>{user.username}</Link></li>
          )
        })}
      </ul>


    </>
  )

}

export default Settings;