import React from 'react';
import { ListGroup, Row } from 'react-bootstrap';

import { useHits, UseHitsProps } from 'react-instantsearch-hooks-web';
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

const MyHits = (props: UseHitsProps) => {


  const { hits, results, sendEvent } = useHits(props);

  return (
    <>
      <ListGroup style={{position: 'absolute', top:'40px'}}>
        {(results?.query !== '') && hits.map(hit => {
          return (
            //@ts-ignore
            <ListGroup.Item key={hit.id as string}> 
              <Link to={'/app/contacts/'+hit.id}> <>{hit.name} {hit.surname}</> </Link>
            </ListGroup.Item>
          )
        })}
      </ListGroup>
    </>
  )


}

export default MyHits;