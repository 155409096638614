import React, { FormEvent, useState } from 'react';
import { Breadcrumb, Button, Form } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { InsuranceState, useCreateCarInsuranceMutation } from '../../generated/graphql';
import { useAuth } from '../../Helpers/authContext';
import graphqlRequestClient from '../../Helpers/graphql-client';
import urls from '../../Helpers/urls'

const options = [
  {value: 'UNKNOWN', label: 'Bilinmiyor'},
  {value: 'US', label: 'Bizde'},
  {value: 'RIVAL', label: 'Rakipte'}
]

const CarInsurancesNew = () => {

  const { acceptedFiles, getRootProps, open, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'application/pdf': ['.pdf']
    },
    noClick: true,
    noKeyboard: true,
  })

  const files = acceptedFiles.map(file => (
    <li key={file.name}>
      {file.name}
    </li>
  ));

  const { user } = useAuth();
  const navigate = useNavigate();
  const { state } : any = useLocation();
  const { mutate } = useCreateCarInsuranceMutation(graphqlRequestClient, {
    onSettled: async res => {
      const formData = new FormData()

      formData.append('file', new Blob([await acceptedFiles[0].arrayBuffer()], { type: 'application/pdf' }));
      console.log(urls.car_pdf_upload(res?.createCarInsurance.id!))
      fetch(urls.car_pdf_upload(res?.createCarInsurance.id!), {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + user.token,
        },
        body: formData
      }).then(() => {
        toast.success('Araç sigortası başarıyla eklendi.')
        navigate('/app/car-insurances/' + res?.createCarInsurance.id)
      })
      
    }
  })

  const [data, setData] = useState({
    title: '',
    status: options[0].value,
    startTime: new Date(),
    endTime: new Date()
  })

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    mutate({
      input: {
        title: data.title,
        state: data.status == 'UNKNOWN' ? InsuranceState.Unknown : data.status == 'US' ? InsuranceState.Us : InsuranceState.Rival,
        car: {
          connect: {
            id: parseInt(state.car.id)
          }
        },
        startTime: data.startTime,
        endTime: data.endTime,
      }
    })

  }


  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to={'/app/contacts'}>Müşteriler</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to={'/app/contacts/' + state?.car?.contact?.id}>{state?.car?.contact?.name + ' ' + state?.car?.contact?.surname}</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to={'/app/properties/cars/' + state?.car?.id}>{state?.car?.plate}</Link></Breadcrumb.Item>
        <Breadcrumb.Item active>Yeni Sigorta</Breadcrumb.Item>
      </Breadcrumb>
      <h1 className='display-2 my-3 text-center text-md-start'> <b>{state?.car?.plate}</b> için yeni sigorta </h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group className='my-2'>
          <Form.Label> Arabanın Plakası </Form.Label>
          <Form.Control size='lg' type="text" disabled value={state?.car?.plate} />
        </Form.Group>
        <Form.Group className='my-2'>
          <Form.Label> Başlık </Form.Label>
          <Form.Control size='lg' type="text" value={data.title} onChange={e => setData({...data, title: e.target.value})} />
        </Form.Group>
        <Form.Group className='my-2'>
          <Form.Label> Durum </Form.Label>
          <Select options={options} defaultValue={options[0]} onChange={option => setData({...data, status: option!.value})} />
        </Form.Group>
        <Form.Group className='my-2'>
          <Form.Label> Başlangıç Zamanı </Form.Label>
          <Form.Control size='lg' type='date' disabled={data.status == 'UNKNOWN'} onChange={e => setData({...data, startTime: new Date(e.target.value)})} />
        </Form.Group>
        <Form.Group className='my-2'>
          <Form.Label> Bitiş Zamanı </Form.Label>
          <Form.Control size='lg' type='date' disabled={data.status == 'UNKNOWN'} onChange={e => setData({...data, endTime: new Date(e.target.value)})} />
        </Form.Group>

        <section className="border-2 my-4 p-2">
          <div {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            <p>PDF'leri sürükleyin veya seçmek için gözatın.</p>
            <Button onClick={open} variant='primary'> Gözat... </Button>
          </div>
          <aside>
            {files.length > 0 ? 
            <><h6>Dosya:</h6>
            <ul>{files}</ul></>
            : null}
          </aside>
        </section>

        <div className='d-grid gap-3 d-md-block my-2'>
        <Button className='py-3 py-md-2' variant="primary" type="submit"> Ekle </Button>
        </div>
      </Form>

      
    </>
  )

}


export default CarInsurancesNew;