import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Outlet, Link, useNavigate, useMatch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useAuth } from './Helpers/authContext';
import Searchbar from './Homepage/Searchbar';
import TopNav from './Homepage/TopNav';

const NavLink = ({ to, text, className }: any) => {

  return <Link to={to}
    className={'no-underline hover:text-black text-white text-center m-2 p-2 mx-1 border rounded hover:bg-white ' + className}
  > {text} </Link>
}


const App = () => {
  const { user } = useAuth();
  const navigate = useNavigate()
  useEffect(() => {
    if (user.token == null) {
      navigate('/');
    }
  }, [])

  // should only close at small devices
  const handleCloseNavbar = () => {
    document.body.classList.toggle('sb-sidenav-toggled');
  }

  return (
    <>
      <TopNav />
      <div id="layoutSidenav">
        <div id="layoutSidenav_nav">
          <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
            <div className="sb-sidenav-menu">
              <div className="nav pt-10">
                <Link className="nav-link" to="/app/">
                  <div className="sb-nav-link-icon"><i className="fa-solid fa-house-chimney"></i></div>
                  Anasayfa
                </Link>
                <Link className="nav-link" to="/app/contacts">
                  <div className="sb-nav-link-icon"><i className="fas fa-users"></i></div>
                  Müşteriler
                </Link>
                <Link className="nav-link" to="/app/events">
                  <div className="sb-nav-link-icon"><i className="fas fa-calendar"></i></div>
                  Takvim
                </Link>
                <Link className="nav-link" to="/app/settings">
                  <div className="sb-nav-link-icon"><i className="fas fa-gears"></i></div>
                  Ayarlar
                </Link>
              </div>
            </div>
            <div className="sb-sidenav-footer">
              <div className="small">Aktif Kullanıcı:</div>
              { user.username }
            </div>
          </nav>
        </div>
        <div id="layoutSidenav_content">
          <main className="p-1 p-md-4">
            <Outlet />
          </main>
          <footer className="py-4 bg-light mt-auto">
            <div className="container-fluid px-4">
              <div className="d-flex align-items-center justify-content-between small">
                {/**
                <div className="text-muted">Copyright &copy; Your Website 2022</div>
                <div>
                  <a href="#">Privacy Policy</a>
                  &middot;
                  <a href="#">Terms &amp; Conditions</a>
                </div>
                 */}
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );


}

export default App;