import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Form, Modal, Table } from 'react-bootstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns'
import { tr } from 'date-fns/locale'
import { useGetOneCarQuery, useRemoveCarMutation } from '../../generated/graphql';
import graphqlRequestClient from '../../Helpers/graphql-client';

const CarsView = () => {
  // TODO: get info with query, AND remove state from contactsview link 
  const navigate = useNavigate();
  const urlParams = useParams();
  const id = Number(urlParams.id)

  const { data } = useGetOneCarQuery(graphqlRequestClient, { id })
  const { mutate: removeCarMut } = useRemoveCarMutation(graphqlRequestClient, { 
    onSuccess: () => {
      navigate('/app/contacts/' + data?.car.contact.id);
    }
   })

  const [showModal, setShowModal] = useState(false);
  const [modalInput, setModalInput] = useState('');
  useEffect(() => {
    setModalInput('');
  }, [showModal])
  
  const handleNewInsurance = () => {
    navigate('/app/car-insurances/new', {state: { car: data?.car }});
  }

  const handleUpdateCar = (e: any) => {
    navigate('/app/properties/cars/update/' + data?.car.id, {state: { car: data?.car, owner: data?.car.contact }})
  }

  const handleDeleteCar = () => {
    removeCarMut({ id: Number(data?.car.id) })
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to={'/app/contacts'}>Müşteriler</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to={'/app/contacts/'+data?.car.contact.id}>{data?.car.contact.name + ' ' + data?.car.contact.surname}</Link></Breadcrumb.Item>
        <Breadcrumb.Item active>{data?.car.plate}</Breadcrumb.Item>
      </Breadcrumb>
      <p> Araç: </p>
      <h2 className='text-center text-md-start display-2 mb-3'> {data?.car.plate} </h2>
      
      <p className="mt-4"> Sahibi: <Link to={'/app/contacts/'+data?.car.contact.id}>{ data?.car.contact.name + ' ' + data?.car.contact.surname }</Link></p>
      <p> Plakası: { data?.car.plate } </p>
      <p> Belge Seri No: { data?.car.documentSerial } </p>
      <p> Araç Kodu Tipi: { data?.car.typeCode } </p>
      <p> Model Yılı: { data?.car.modelYear } </p>
      <Table striped className='my-5'>
        <thead>
          <tr>
            <th> Sigorta Adı </th>
            <th> Durum </th>
            <th> Başlangıç Tarihi </th>
            <th> Bitiş Tarihi </th>
          </tr>
        </thead>
        <tbody>
          { data?.car.carInsurances.map((insurance:any) => {
            return (
              <tr key={insurance.id}>
                <td><Link to={'/app/car-insurances/' + insurance.id}>{insurance.title}</Link></td>
                <td>{insurance.state == 'US' ? 'Bizde' : insurance.state == 'UNKNOWN' ? 'Bilgi yok' : 'Rakipte'}</td>
                <td>{insurance.startTime ? format(new Date(insurance.startTime), 'dd LLLL yyyy', { locale: tr}) : '-'}</td>
                <td>{insurance.endTime ? format(new Date(insurance.endTime), 'dd LLLL yyyy', { locale: tr}) : '-'}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      <div className='d-grid gap-2 d-md-block'>
      <Button className='py-3 py-md-2' variant="info" onClick={handleNewInsurance}> Sigorta Ekle </Button>
      <Button className='py-3 py-md-2' variant="warning" onClick={handleUpdateCar}> Aracı Düzenle </Button>
      <Button className='py-3 py-md-2' variant="danger" onClick={() => setShowModal(true)}> Aracı Sil </Button>
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header>
          <Modal.Title> Aracı Sil </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p> <b> {data?.car.plate} </b> plakalı aracı silmek için 
          aracın plakasını KALIN YAZILDIĞI ŞEKİLDE 
          (büyük küçük harf duyarlı) yazınız.</p>
          <p> Silme işlemi bu araca ait bütün sigorta belgelerini de siler. Bu işlem geri alınamaz. </p>
          <Form.Control type="text" value={modalInput} onChange={(e: any) => setModalInput(e.target.value)} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}> İptal </Button>
          <Button variant="danger" disabled={data?.car.plate !== modalInput}
            onClick={handleDeleteCar}
          > Aracı Sil </Button>
        </Modal.Footer>
      </Modal>
    </>
  )

}

export default CarsView;