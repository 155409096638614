import React, { FormEvent, useState } from 'react';
import { Breadcrumb, Button, Form } from 'react-bootstrap';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUpdateCarMutation } from '../../generated/graphql';
import graphqlRequestClient from '../../Helpers/graphql-client';


const CarsUpdate = () => {

  const { state }: any = useLocation();
  const navigate = useNavigate()

  const [data, setData] = useState({
    plate: state.car.plate,
    documentSerial: state.car.documentSerial,
    typeCode: state.car.typeCode,
    modelYear: state.car.modelYear,
  })

  const { mutate } = useUpdateCarMutation(graphqlRequestClient, {
    onSuccess: (res) => navigate('/app/properties/cars/' + state.car.id)
  })

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    mutate({
      id: parseInt(state.car.id),
      input: {
        plate: {
          set: data.plate
        },
        documentSerial: {
          set: data.documentSerial
        },
        typeCode: {
          set: data.typeCode
        },
        modelYear: {
          set: data.modelYear
        }
      }
    })
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to={'/app/contacts'}>Müşteriler</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to={'/app/contacts/' + state.owner.id}>{state.owner.name + ' ' + state.owner.surname}</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to={'/app/properties/cars/' + state.car.id}>{state.car.plate}</Link></Breadcrumb.Item>
        <Breadcrumb.Item active>Düzenle</Breadcrumb.Item>
      </Breadcrumb>
      <p> Aracı Düzenle: </p>
      <h1 className='display-2 text-center text-md-start'>{state.car.plate}</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label> Sahibi </Form.Label>
          <Form.Control size='lg' disabled value={state.owner.name + ' ' + state.owner.surname} />
        </Form.Group>
        <Form.Group>
          <Form.Label> Plaka </Form.Label>
          <Form.Control size='lg' type="text" value={data.plate} onChange={e => setData({ ...data, plate: e.target.value })} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Belge Seri No</Form.Label>
          <Form.Control size='lg' value={data.documentSerial} onChange={e => setData({ ...data, documentSerial: e.target.value })} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Araç Tipi Kodu</Form.Label>
          <Form.Control size='lg' value={data.typeCode} onChange={e => setData({ ...data, typeCode: e.target.value })} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Model Yılı</Form.Label>
          <Form.Control size='lg' value={data.modelYear} onChange={e => setData({ ...data, modelYear: e.target.value })} />
        </Form.Group>
        <div className='d-grid gap-3 d-md-block mt-3'>
          <Button className='py-3 py-md-2' type='submit'> Aracı Güncelle </Button>
        </div>
      </Form>
    </>
  )

}

export default CarsUpdate