import React, { FormEvent, useEffect, useState } from 'react';
import { Form, Button, Container, Row, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import graphqlRequestClient from './Helpers/graphql-client';

import { useAuth } from './Helpers/authContext';
import url from './Helpers/urls'

const Login = () => {
  const { loginUser } = useAuth();
  const navigate = useNavigate();
  const [state, setState] = useState({
    officename: '',
    username: '',
    password: '',
    showError: false,
    errorMessage: ''
  })

  useEffect(() => {
    setState({ ...state, showError: false })
  }, [state.officename, state.username, state.password])

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    // TODO: implement
    fetch(url.login, {
      method: 'POST',
      mode: 'cors',
      headers: [
        ['Content-Type', 'application/json']
      ],
      body: JSON.stringify({
        officename: state.officename,
        username: state.username,
        password: state.password
      })
    })
      .then(res => res.json())
      .then(json => {
        if (json.access_token) {
          graphqlRequestClient.setHeader('Authorization', 'Bearer ' + json.access_token)
          loginUser({
            token: json.access_token,
            username: state.username,
            officename: state.officename,
            meiliToken: json.meiliToken
          });
          navigate('/app/', {});
        }
        else {
          setState({
            ...state,
            showError: true,
            errorMessage: 'Giriş yapılamadı, lütfen şifrenizin doğru olduğundan emin olunuz.'
          })
        }
      })
      .catch(err => console.error(err));


  }

  return (
    <Container className="mt-5">

      <Row className="justify-content-center">


        <div className='text-center h1'>JEST Sigorta</div>
        <a className="col-md-8 py-2 lead text-center" href='https://jest.digital/landing'> Websitesi</a>
        {state.showError &&
          <Alert variant='danger' className='text-center col-md-8' >
            {state.errorMessage}
          </Alert>
        }

        <Form onSubmit={handleSubmit} className="col-12 col-md-8">
          <Form.Group className="mb-3">
            <Form.Label>Ofis Adı</Form.Label>
            <Form.Control type="text" placeholder="Ofisinizin adı"
              value={state.officename}
              onChange={e => setState({ ...state, officename: e.target.value })}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Kullanıcı Adı</Form.Label>
            <Form.Control type="text" placeholder="Kullanıcı adınız"
              value={state.username}
              onChange={e => setState({ ...state, username: e.target.value })}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Şifre</Form.Label>
            <Form.Control type="password" placeholder="Şifreinz"
              value={state.password}
              onChange={e => setState({ ...state, password: e.target.value })}
            />
          </Form.Group>
          {/*
          <Form.Group className="mb-3">
            <Form.Check type="checkbox" label="Beni Hatırla" />
          </Form.Group>
          */}


          <Button variant="primary" type="submit">
            Giriş Yap
          </Button>
        </Form>
      </Row>
    </Container>
  )


}

export default Login;
