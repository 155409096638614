import React, { FormEvent, useState } from 'react';
import { Breadcrumb, Button, Form } from 'react-bootstrap';
import { useCreateContactMutation } from '../generated/graphql';
import graphqlRequestClient from '../Helpers/graphql-client';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ContactsNew = () => {

  const navigate = useNavigate();
  const { mutate, isLoading } = useCreateContactMutation(graphqlRequestClient, {
    onSettled: (res) => {
      navigate('/app/contacts/' + res?.createContact.id)
      toast.success('Yeni müşteri başarıyla eklendi.')
    }
  }, {});


  const [data, setData] = useState({
    name: '',
    surname: '',
    turkish_id: '',
    birthdate: new Date(),
    phone: ''
  });

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    mutate({
      input: {
        name: data.name,
        surname: data.surname,
        turkish_id: data.turkish_id,
        phone: data.phone,
        birthdate: data.birthdate
      }
    });

  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to={'/app/contacts'}>Müşteriler</Link></Breadcrumb.Item>
        <Breadcrumb.Item active> Yeni Müşteri </Breadcrumb.Item>
      </Breadcrumb>
      <h1 className='display-1 text-center text-md-start my-3'> Yeni Müşteri </h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group className='my-2'>
          <Form.Label> Ad </Form.Label>
          <Form.Control type='text' size='lg' value={data.name} onChange={(e) => setData({ ...data, name: e.target.value })} />
        </Form.Group>

        <Form.Group className='my-2'>
          <Form.Label> Soyad </Form.Label>
          <Form.Control type='text' size='lg' value={data.surname} onChange={(e) => setData({ ...data, surname: e.target.value })} />
        </Form.Group>

        <Form.Group className='my-2'>
          <Form.Label>Doğum Tarihi</Form.Label>
          <Form.Control type="date" size='lg' onChange={e => setData({ ...data, birthdate: new Date(e.target.value) })} />
        </Form.Group>

        <Form.Group className='my-2'>
          <Form.Label>Telefon</Form.Label>
          <Form.Control type="text" size='lg' value={data.phone} onChange={e => setData({ ...data, phone: e.target.value })} />
        </Form.Group>

        <Form.Group className='my-2'>
          <Form.Label> T.C. Kimlik Numarası </Form.Label>
          <Form.Control type='text' size='lg' value={data.turkish_id} onChange={(e) => setData({ ...data, turkish_id: e.target.value })} />
        </Form.Group>
        <div className='d-grid gap-2 d-md-block my-3'>
          <Button className='py-3 py-md-2' type='submit' disabled={isLoading}> Müşteriyi Kaydet </Button>
        </div>
      </Form>
    </>
  )
}

export default ContactsNew;