import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import { QueryClientProvider, QueryClient } from 'react-query'

import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { ToastContainer } from 'react-toastify';

import App from './App';
import { AuthProvider } from './Helpers/authContext';
import Login from './Login';
import ContactsApp from './Contacts/ContactsApp';
import ContactsIndex from './Contacts/ContactsIndex';
import ContactsNew from './Contacts/ContactsNew';
import ContactsUpdate from './Contacts/ContactsUpdate';
import './styles/index.css';
import './styles/styles.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import ContactsView from './Contacts/ContactsView';
import EventsApp from './Events/EventsApp';
import EventsIndex from './Events/EventsIndex';
import PropertiesApp from './Properties/PropertiesApp';
import PropertiesNew from './Properties/PropertiesNew';
import CarsNew from './Properties/Cars/CarsNew';
import EstatesNew from './Properties/Estates/EstatesNew';
import CarInsurancesNew from './Insurances/CarInsurances/CarInsurancesNew';

import 'react-toastify/dist/ReactToastify.min.css'
import CarsView from './Properties/Cars/CarsView';
import CarsUpdate from './Properties/Cars/CarsUpdate';
import CarInsuranceView from './Insurances/CarInsurances/CarInsuranceView';
import CarInsurancesUpdate from './Insurances/CarInsurances/CarInsurancesUpdate';
import EventsNew from './Events/EventsNew';
import EventsUpdate from './Events/EventsUpdate';

import Settings from './Settings/Settings';
import UserView from './Settings/UserView';
import UserUpdate from './Settings/UserUpdate';
import UserNew from './Settings/UserNew';
import Dashboard from './Dashboard/Dashboard';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient} >
      <AuthProvider>
        <ToastContainer />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Login />}></Route>
              <Route path="/app" element={<App />}>
                <Route path="" element={<Dashboard/>} />

                <Route path="contacts" element={<ContactsApp />}>
                  <Route path="" element={<ContactsIndex />} />
                  <Route path="new" element={<ContactsNew />} />
                  <Route path="update/:id" element={<ContactsUpdate />}/>
                  <Route path=":id" element={<ContactsView />}/>
                </Route>

                <Route path="events" element={<EventsApp />}>
                  <Route path="" element={<EventsIndex />} />
                  <Route path="new" element={<EventsNew />} />
                  <Route path="update/:id" element={<EventsUpdate />} />
                </Route>

                <Route path="properties" element={<PropertiesApp />}>
                  <Route path="new" element={<PropertiesNew />} />
                  <Route path="cars">
                    <Route path="update/:id" element={<CarsUpdate />} />
                    <Route path=":id" element={<CarsView />} /> 
                  </Route>
                </Route>

                <Route path="car-insurances">
                  <Route path="new" element={<CarInsurancesNew />} />
                  <Route path="update/:id" element={<CarInsurancesUpdate />} />
                  <Route path=":id" element={<CarInsuranceView />} />
                </Route>

                <Route path="settings" element={<Settings />}>
                </Route>

                <Route path="users">
                  <Route path="new" element={<UserNew />} />
                  <Route path="update/:id" element={<UserUpdate />} />
                  <Route path=":id" element={<UserView />} />
                  
                </Route>


              </Route>
            </Routes>

          </BrowserRouter>
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
