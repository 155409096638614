import React, { FormEvent, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCreateEventMutation, useDeleteEventMutation, useUpdateEventMutation } from '../generated/graphql';
import graphqlRequestClient from '../Helpers/graphql-client';
import { format } from 'date-fns';


const EventsUpdate = () => {

  const navigate = useNavigate();
  const { state } : any = useLocation();

  const [showModal, setShowModal] = useState(false);
  const [modalInput, setModalInput] = useState('');

  const [data, setData] = useState({
    title: state.event.title,
    startTime: format(new Date(state.event.start), "yyyy-MM-dd'T'kk':'mm"),
    endTime: format(new Date(state.event.end), "yyyy-MM-dd'T'kk':'mm"),
    note: state.event.note
  })

  
  const { mutate } = useUpdateEventMutation(graphqlRequestClient, {
    onSuccess: () => {
      navigate('/app/events')
    }
  })

  const { mutate: deleteM } = useDeleteEventMutation(graphqlRequestClient, {
    onSuccess: () => {
      navigate('/app/events')
    }
  })
  
  const handleDeleteEvent = (e:any) => {
    e.preventDefault();
    deleteM({
      id: parseInt(state.event.id)
    })
  }
  

  const handleSubmit = (e:FormEvent) => {
    e.preventDefault();
    mutate({
      id: parseInt(state.event.id),
      input: {
        title: {
          set: data.title
        },
        startTime: {
          set: data.startTime
        },
        endTime: {
          set: data.endTime
        },
        note: {
          set: data.note
        }
      }
    })
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label> Başlık </Form.Label>
          <Form.Control type="text" value={data.title} onChange={e => setData({...data, title: e.target.value})} />
        </Form.Group>
        <Form.Group>
          <Form.Label> Başlangıç Zamanı </Form.Label>
          <Form.Control type='datetime-local' value={data.startTime} onChange={e => setData({...data, startTime: e.target.value})} />
        </Form.Group>
        <Form.Group>
          <Form.Label> Bitiş Zamanı </Form.Label>
          <Form.Control type='datetime-local' value={data.endTime} onChange={e => setData({...data, endTime: e.target.value})} />
        </Form.Group>
        <Form.Group>
          <Form.Label> Notlar </Form.Label>
          <Form.Control type='text' as="textarea" value={data.note} rows={3} onChange={e => setData({...data, note: e.target.value})} />
        </Form.Group>
        <Button variant="primary" type="submit"> Kaydet </Button>
        <Button variant="danger" onClick={e => setShowModal(true)}> Etkinliği Sil </Button>
      </Form>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header>
          <Modal.Title> Etkinliği Sil </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p> <b> {state.event.title} </b> başlıklı etkinliği silmek için etkinliğin başlığını
          (büyük küçük harf duyarlı) yazınız.</p>
          <p> Silme işlemi bu etkinliği kalıcı şekilde siler. Bu işlem geri alınamaz. </p>
          <Form.Control type="text" value={modalInput} onChange={(e: any) => setModalInput(e.target.value)} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}> İptal </Button>
          <Button variant="danger" disabled={state.event.title !== modalInput}
            onClick={handleDeleteEvent}
          > Etkinliği Sil </Button>
        </Modal.Footer>
      </Modal>
    </>
  )

}

export default EventsUpdate;
