
const urls_dev = {
  meili: 'http://localhost:7700/',
  graphql: 'http://localhost:3000/graphql/',
  login: 'http://localhost:3000/auth/login/',
  car_pdf_get: (car_insurance_id: number | string) => 'http://localhost:3000/car-insurances/get-pdf/' + car_insurance_id,
  car_pdf_upload: (car_insurance_id: number | string) => 'http://localhost:3000/car-insurances/upload-pdf/' + car_insurance_id,
  contact_import_xlsx: 'http://localhost:3000/contacts/import-xlsx',
  car_import_xlsx: 'http://localhost:3000/cars/import-xlsx',
  car_insurance_import_xlsx: 'http://localhost:3000/car-insurances/import-xlsx',
} 

const urls_prod = {
  meili: 'https://jest.digital:7701/',
  graphql: 'https://jest.digital/graphql/',
  login: 'https://jest.digital/auth/login/',
  car_pdf_get: (car_insurance_id: number | string) => 'https://jest.digital/car-insurances/get-pdf/' + car_insurance_id,
  car_pdf_upload: (car_insurance_id: number | string) => 'https://jest.digital/car-insurances/upload-pdf/' + car_insurance_id,
  contact_import_xlsx: 'https://jest.digital/contacts/import-xlsx',
  car_import_xlsx: 'https://jest.digital/cars/import-xlsx',
  car_insurance_import_xlsx: 'https://jest.digital/car-insurances/import-xlsx'
}



export default urls_prod;
