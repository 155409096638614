import React, { useState } from 'react';
import { Button, Col, Container, Form, ListGroup, Modal, Pagination, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import graphqlRequestClient from '../Helpers/graphql-client';
import { useGetContactsPagedQuery } from '../generated/graphql';
import { useDropzone } from 'react-dropzone';
import urls from '../Helpers/urls';
import { useAuth } from '../Helpers/authContext';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';


const ContactsIndex = () => {

  const navigate = useNavigate();
  const { user } = useAuth();
  const qClient = useQueryClient()

  const { acceptedFiles, getRootProps, open, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
    },
    noClick: true,
    noKeyboard: true,
  })

  const files = acceptedFiles.map(file => (
    <li key={file.name}>
      {file.name}
    </li>
  ));



  const [state, setState] = useState({
    page: 1,
    showImportModal: false,
    importLoading: false,
    importModalTabKey: "contacts"
  })

  const { data, isLoading } = useGetContactsPagedQuery(graphqlRequestClient, { page: state.page }, {
    keepPreviousData: true,
  })

  const handleClick = (e: any, id: string) => {
    navigate(id);
  }

  const handleImportButton = (e: any) => {
    setState({ ...state, showImportModal: true })
  }

  const handleImport = async (e: any) => {
    let url = '';
    if (state.importModalTabKey == 'contacts') {
      url = urls.contact_import_xlsx
    } else if (state.importModalTabKey == 'cars') {
      url = urls.car_import_xlsx
    } else if (state.importModalTabKey == 'car-insurances') {
      url = urls.car_insurance_import_xlsx
    } else {
      // Shouldn't happen
      console.error("Error choosing upload target for excel")
    }
    setState({...state, importLoading: true})
    const formData = new FormData()
    formData.append('file', new Blob([await acceptedFiles[0].arrayBuffer()], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
    await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + user.token
      },
      body: formData
    })
      .then(res => res.json())
      .then(json => {
        if(json.msg == 'Done'){
          toast.success('Başarıyla yüklendi.')
        }
      })
      .catch(err => {
        console.error(err)
        toast.error('Yükleme yapılırken bir hatayla karşılaşıldı. Destekle iletişime geçin.')
      })
    setState({...state, showImportModal: false, importLoading: false})
    qClient.refetchQueries(['GetContactsPaged'])
  }

  return (
    <div>
      <h1 className='my-3 display-1 text-center text-md-start'> Müşteriler </h1>
      <p className='text-end text-md-start'> {data?.contactsCount} Müşteri </p>

      
      <div className='d-grid gap-2 d-md-block'>
        <Button className='py-3 py-md-2 mb-0 mb-md-2' variant='success' onClick={handleImportButton}> Excel'den İçeri Al </Button> <br className='d-none d-md-block' />
        <Link to='new' className='btn btn-primary mb-3 py-3 py-md-2'> Yeni Müşteri </Link>
      </div>

      {!isLoading &&
        <Pagination className='mx-auto mx-md-0'  size='lg' style={{ width: 'fit-content' }}>
          <Pagination.First disabled={state.page <= 1} onClick={e => setState({ ...state, page: 1 })} />
          <Pagination.Prev disabled={state.page <= 1} onClick={e => setState({ ...state, page: state.page - 1 })} />
          <Pagination.Item active>{state.page}</Pagination.Item>
          {/*@ts-ignore*/}
          <Pagination.Next disabled={state.page >= Math.ceil(data?.contactsCount / 25)} onClick={e => setState({ ...state, page: state.page + 1 })} />
          {/*@ts-ignore*/}
          <Pagination.Last disabled={state.page >= Math.ceil(data?.contactsCount / 25)} onClick={e => setState({ ...state, page: Math.ceil(data!.contactsCount / 25) })} />
        </Pagination>
      }
      <ListGroup className="mb-1">
        {data?.contacts.map(contact => {
          return (<ListGroup.Item action onClick={e => handleClick(e, contact.id)} key={contact.id}>{contact.name + " " + contact.surname.toUpperCase()}</ListGroup.Item>)
        })}

      </ListGroup>

      {!isLoading &&
        <Pagination className='mt-2 mx-auto mx-md-0'  size='lg' style={{ width: 'fit-content' }}>
          <Pagination.First disabled={state.page <= 1} onClick={e => setState({ ...state, page: 1 })} />
          <Pagination.Prev disabled={state.page <= 1} onClick={e => setState({ ...state, page: state.page - 1 })} />
          <Pagination.Item active>{state.page}</Pagination.Item>
          {/*@ts-ignore*/}
          <Pagination.Next disabled={state.page >= Math.ceil(data?.contactsCount / 25)} onClick={e => setState({ ...state, page: state.page + 1 })} />
          {/*@ts-ignore*/}
          <Pagination.Last disabled={state.page >= Math.ceil(data?.contactsCount / 25)} onClick={e => setState({ ...state, page: Math.ceil(data!.contactsCount / 25) })} />
        </Pagination>
      }


      <Modal
        show={state.showImportModal}
        onHide={() => setState({ ...state, showImportModal: false })}
      >
        <Modal.Header>
          <Modal.Title> Excel'den İçeri Aktar </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Tabs 
            defaultActiveKey="contacts"
            className='mb-3'
            activeKey={state.importModalTabKey}
            onSelect={key => setState({...state, importModalTabKey: key!})}
          >
            <Tab eventKey="contacts" title="Müşteriler">
              <h2> Müşterileri Excelden İçeri Al </h2>
              <p> Excel dosyasının ilk satırı dikkate alınmayacaktır. </p>
              <p> Excelin sütunları sırasıyla şöyle olmalı: </p>
              <ListGroup as="ol" numbered>
                <ListGroup.Item as="li">T.C. Kimlik Numarası</ListGroup.Item>
                <ListGroup.Item as="li">Ad*</ListGroup.Item>
                <ListGroup.Item as="li">Soyad*</ListGroup.Item>
                <ListGroup.Item as="li">Doğum tarihi (excelde tarih olarak formatlanmalı)</ListGroup.Item>
                <ListGroup.Item as="li">Telefon Numarası</ListGroup.Item>
              </ListGroup>
            </Tab>
            <Tab eventKey="cars" title="Araçlar">
              <h2> Araçları Excelden İçeri Al </h2>
              <p> Excelin sütunları sırasıyla şöyle olmalı: </p>
              <ListGroup as="ol" numbered>
                <ListGroup.Item as="li">T.C. Kimlik Numarası*</ListGroup.Item>
                <ListGroup.Item as="li">Plaka*</ListGroup.Item>
                <ListGroup.Item as="li">Doküman Seri Numarası</ListGroup.Item>
                <ListGroup.Item as="li">Araç Tip Kodu</ListGroup.Item>
                <ListGroup.Item as="li">Model Yılı</ListGroup.Item>
              </ListGroup>
            </Tab>
            <Tab eventKey="car-insurances" title="Araç Sigortaları">
              <h2> Araç Sigortalarını İçeri Al </h2>
              <p> Excelin sütunları sırasıyla şöyle olmalı: </p>
              <ListGroup as="ol" numbered>
                <ListGroup.Item as="li">Plaka*</ListGroup.Item>
                <ListGroup.Item as="li">Sigortanın Adı*</ListGroup.Item>
                <ListGroup.Item as="li">Yapıldığı Tarih* (excelde tarih olarak formatlanmalı)</ListGroup.Item>
              </ListGroup>
            </Tab>
          </Tabs>
          <p className='pt-2'>* ile işaretli alanlar mecburidir. Diğer bilgiler yoksa onların yerine <u>boşluk</u> bırakınız.</p>
          <p className='text-danger pt-2'>Yükleme işlemi satır sayısıyla orantılı olarak uzun sürebilir, lütfen pencereyi kapatmayınız.</p>
          <section className="border-2 p-2">
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <p>Exceli sürükleyin veya gözatmak için tıklayın</p>
              <Button onClick={open} variant='primary'> Gözat... </Button>
            </div>
            <aside>
              {files.length > 0 ?
                <><h6>Dosya:</h6>
                  <ul>{files}</ul></>
                : null}
            </aside>
          </section>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setState({ ...state, showImportModal: false })}> İptal </Button>
          <Button variant="success" onClick={handleImport} disabled={state.importLoading}> Yükle </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ContactsIndex;