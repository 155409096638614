import React, { FormEvent, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCreateEventMutation } from '../generated/graphql';
import graphqlRequestClient from '../Helpers/graphql-client';

const EventsNew = () => {

  const navigate = useNavigate();
  const { state } : any = useLocation();

  const [data, setData] = useState({
    title: '',
    startTime: new Date(),
    endTime: new Date(),
    note: ''
  })

  const { mutate } = useCreateEventMutation(graphqlRequestClient, {
    onSuccess: () => {
      navigate('/app/events')
    }
  })

  const handleSubmit = (e:FormEvent) => {
    e.preventDefault();
    console.log(data)
    mutate({
      input: {
        title: data.title,
        note: data.note,
        startTime: data.startTime,
        endTime: data.endTime
      }
    })
    
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label> Başlık </Form.Label>
          <Form.Control type="text" value={data.title} onChange={e => setData({...data, title: e.target.value})} />
        </Form.Group>
        <Form.Group>
          <Form.Label> Başlangıç Zamanı </Form.Label>
          <Form.Control type='datetime-local' onChange={e => setData({...data, startTime: new Date(e.target.value)})} />
        </Form.Group>
        <Form.Group>
          <Form.Label> Bitiş Zamanı </Form.Label>
          <Form.Control type='datetime-local' onChange={e => setData({...data, endTime: new Date(e.target.value)})} />
        </Form.Group>
        <Form.Group>
          <Form.Label> Notlar </Form.Label>
          <Form.Control type='text' as="textarea" rows={3} onChange={e => setData({...data, note: e.target.value})} />
        </Form.Group>
        <Button variant="primary" type="submit"> Ekle </Button>
      </Form>
    </>
  )

}

export default EventsNew;
