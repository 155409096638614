import React from 'react';
//@ts-ignore
import { InstantSearch, SearchBox, Hits, Highlight } from 'react-instantsearch-hooks-web';
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import MyHits from './Hit';
import { Form } from 'react-bootstrap';
import { useSearchBox, UseSearchBoxProps } from 'react-instantsearch-hooks-web';
import { useAuth } from '../Helpers/authContext';

import url from '../Helpers/urls'



const Searchbar = () => {

  const { user } = useAuth();
  
  const searchClient = instantMeiliSearch(
    url.meili,
    user.meiliToken //user.meiliToken
  );

  return (
    <>
      <InstantSearch
        indexName="contacts"
        searchClient={searchClient}

      >
        <MySearchBox />
        <MyHits />
      </InstantSearch>
      
    </>
  )

}

const MySearchBox = (props:UseSearchBoxProps) => {

  const { query, refine, clear, isSearchStalled } = useSearchBox(props)

  return (
    <>
      <Form.Control 
        placeholder="Aramak İçin Yazmaya Başlayın..."
        onChange={e => refine(e.target.value)}
        onBlur={e => console.log(e)}
      />
    </>
  )
}


export default Searchbar;