import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { useMutation, useQuery, useInfiniteQuery, UseMutationOptions, UseQueryOptions, UseInfiniteQueryOptions, QueryFunctionContext } from 'react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

function fetcher<TData, TVariables>(client: GraphQLClient, query: string, variables?: TVariables, headers?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request<TData, TVariables>(query, variables, headers);
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type Car = {
  __typename?: 'Car';
  _count: CarCount;
  carInsurances: Array<CarInsurance>;
  contact: Contact;
  contactId: Scalars['Int'];
  documentSerial?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  insurances?: Maybe<Array<CarInsurance>>;
  modelYear?: Maybe<Scalars['String']>;
  office: Office;
  officeId: Scalars['Int'];
  plate: Scalars['String'];
  typeCode?: Maybe<Scalars['String']>;
};

export type CarCount = {
  __typename?: 'CarCount';
  insurances: Scalars['Int'];
};

export type CarCreateManyContactInput = {
  documentSerial?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  modelYear?: InputMaybe<Scalars['String']>;
  officeId: Scalars['Int'];
  plate: Scalars['String'];
  typeCode?: InputMaybe<Scalars['String']>;
};

export type CarCreateManyContactInputEnvelope = {
  data: Array<CarCreateManyContactInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CarCreateManyOfficeInput = {
  contactId: Scalars['Int'];
  documentSerial?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  modelYear?: InputMaybe<Scalars['String']>;
  plate: Scalars['String'];
  typeCode?: InputMaybe<Scalars['String']>;
};

export type CarCreateManyOfficeInputEnvelope = {
  data: Array<CarCreateManyOfficeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CarCreateNestedManyWithoutContactInput = {
  connect?: InputMaybe<Array<CarWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CarCreateOrConnectWithoutContactInput>>;
  create?: InputMaybe<Array<CarCreateWithoutContactInput>>;
  createMany?: InputMaybe<CarCreateManyContactInputEnvelope>;
};

export type CarCreateNestedManyWithoutOfficeInput = {
  connect?: InputMaybe<Array<CarWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CarCreateOrConnectWithoutOfficeInput>>;
  create?: InputMaybe<Array<CarCreateWithoutOfficeInput>>;
  createMany?: InputMaybe<CarCreateManyOfficeInputEnvelope>;
};

export type CarCreateNestedOneWithoutInsurancesInput = {
  connect?: InputMaybe<CarWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CarCreateOrConnectWithoutInsurancesInput>;
  create?: InputMaybe<CarCreateWithoutInsurancesInput>;
};

export type CarCreateOrConnectWithoutContactInput = {
  create: CarCreateWithoutContactInput;
  where: CarWhereUniqueInput;
};

export type CarCreateOrConnectWithoutInsurancesInput = {
  create: CarCreateWithoutInsurancesInput;
  where: CarWhereUniqueInput;
};

export type CarCreateOrConnectWithoutOfficeInput = {
  create: CarCreateWithoutOfficeInput;
  where: CarWhereUniqueInput;
};

export type CarCreateWithoutContactInput = {
  documentSerial?: InputMaybe<Scalars['String']>;
  insurances?: InputMaybe<CarInsuranceCreateNestedManyWithoutCarInput>;
  modelYear?: InputMaybe<Scalars['String']>;
  office: OfficeCreateNestedOneWithoutCarsInput;
  plate: Scalars['String'];
  typeCode?: InputMaybe<Scalars['String']>;
};

export type CarCreateWithoutInsurancesInput = {
  contact: ContactCreateNestedOneWithoutCarsInput;
  documentSerial?: InputMaybe<Scalars['String']>;
  modelYear?: InputMaybe<Scalars['String']>;
  office: OfficeCreateNestedOneWithoutCarsInput;
  plate: Scalars['String'];
  typeCode?: InputMaybe<Scalars['String']>;
};

export type CarCreateWithoutOfficeInput = {
  contact: ContactCreateNestedOneWithoutCarsInput;
  documentSerial?: InputMaybe<Scalars['String']>;
  insurances?: InputMaybe<CarInsuranceCreateNestedManyWithoutCarInput>;
  modelYear?: InputMaybe<Scalars['String']>;
  plate: Scalars['String'];
  typeCode?: InputMaybe<Scalars['String']>;
};

export type CarInsurance = {
  __typename?: 'CarInsurance';
  car: Car;
  carId: Scalars['Int'];
  endTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  pdf_url?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['DateTime']>;
  state: InsuranceState;
  title: Scalars['String'];
};

export type CarInsuranceCreateInput = {
  car: CarCreateNestedOneWithoutInsurancesInput;
  endTime?: InputMaybe<Scalars['DateTime']>;
  pdf_url?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  state: InsuranceState;
  title: Scalars['String'];
};

export type CarInsuranceCreateManyCarInput = {
  endTime?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  pdf_url?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  state: InsuranceState;
  title: Scalars['String'];
};

export type CarInsuranceCreateManyCarInputEnvelope = {
  data: Array<CarInsuranceCreateManyCarInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CarInsuranceCreateNestedManyWithoutCarInput = {
  connect?: InputMaybe<Array<CarInsuranceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CarInsuranceCreateOrConnectWithoutCarInput>>;
  create?: InputMaybe<Array<CarInsuranceCreateWithoutCarInput>>;
  createMany?: InputMaybe<CarInsuranceCreateManyCarInputEnvelope>;
};

export type CarInsuranceCreateOrConnectWithoutCarInput = {
  create: CarInsuranceCreateWithoutCarInput;
  where: CarInsuranceWhereUniqueInput;
};

export type CarInsuranceCreateWithoutCarInput = {
  endTime?: InputMaybe<Scalars['DateTime']>;
  pdf_url?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  state: InsuranceState;
  title: Scalars['String'];
};

export type CarInsuranceScalarWhereInput = {
  AND?: InputMaybe<Array<CarInsuranceScalarWhereInput>>;
  NOT?: InputMaybe<Array<CarInsuranceScalarWhereInput>>;
  OR?: InputMaybe<Array<CarInsuranceScalarWhereInput>>;
  carId?: InputMaybe<IntFilter>;
  endTime?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  pdf_url?: InputMaybe<StringNullableFilter>;
  startTime?: InputMaybe<DateTimeNullableFilter>;
  state?: InputMaybe<EnumInsuranceStateFilter>;
  title?: InputMaybe<StringFilter>;
};

export type CarInsuranceUncheckedUpdateInput = {
  carId?: InputMaybe<IntFieldUpdateOperationsInput>;
  endTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  pdf_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  startTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  state?: InputMaybe<EnumInsuranceStateFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CarInsuranceUncheckedUpdateManyWithoutCarInput = {
  connect?: InputMaybe<Array<CarInsuranceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CarInsuranceCreateOrConnectWithoutCarInput>>;
  create?: InputMaybe<Array<CarInsuranceCreateWithoutCarInput>>;
  createMany?: InputMaybe<CarInsuranceCreateManyCarInputEnvelope>;
  delete?: InputMaybe<Array<CarInsuranceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CarInsuranceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CarInsuranceWhereUniqueInput>>;
  set?: InputMaybe<Array<CarInsuranceWhereUniqueInput>>;
  update?: InputMaybe<Array<CarInsuranceUpdateWithWhereUniqueWithoutCarInput>>;
  updateMany?: InputMaybe<Array<CarInsuranceUpdateManyWithWhereWithoutCarInput>>;
  upsert?: InputMaybe<Array<CarInsuranceUpsertWithWhereUniqueWithoutCarInput>>;
};

export type CarInsuranceUpdateManyMutationInput = {
  endTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  pdf_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  startTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  state?: InputMaybe<EnumInsuranceStateFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CarInsuranceUpdateManyWithWhereWithoutCarInput = {
  data: CarInsuranceUpdateManyMutationInput;
  where: CarInsuranceScalarWhereInput;
};

export type CarInsuranceUpdateManyWithoutCarInput = {
  connect?: InputMaybe<Array<CarInsuranceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CarInsuranceCreateOrConnectWithoutCarInput>>;
  create?: InputMaybe<Array<CarInsuranceCreateWithoutCarInput>>;
  createMany?: InputMaybe<CarInsuranceCreateManyCarInputEnvelope>;
  delete?: InputMaybe<Array<CarInsuranceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CarInsuranceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CarInsuranceWhereUniqueInput>>;
  set?: InputMaybe<Array<CarInsuranceWhereUniqueInput>>;
  update?: InputMaybe<Array<CarInsuranceUpdateWithWhereUniqueWithoutCarInput>>;
  updateMany?: InputMaybe<Array<CarInsuranceUpdateManyWithWhereWithoutCarInput>>;
  upsert?: InputMaybe<Array<CarInsuranceUpsertWithWhereUniqueWithoutCarInput>>;
};

export type CarInsuranceUpdateWithWhereUniqueWithoutCarInput = {
  data: CarInsuranceUpdateWithoutCarInput;
  where: CarInsuranceWhereUniqueInput;
};

export type CarInsuranceUpdateWithoutCarInput = {
  endTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  pdf_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  startTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  state?: InputMaybe<EnumInsuranceStateFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CarInsuranceUpsertWithWhereUniqueWithoutCarInput = {
  create: CarInsuranceCreateWithoutCarInput;
  update: CarInsuranceUpdateWithoutCarInput;
  where: CarInsuranceWhereUniqueInput;
};

export type CarInsuranceWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type CarOfficeIdPlateCompoundUniqueInput = {
  officeId: Scalars['Int'];
  plate: Scalars['String'];
};

export type CarScalarWhereInput = {
  AND?: InputMaybe<Array<CarScalarWhereInput>>;
  NOT?: InputMaybe<Array<CarScalarWhereInput>>;
  OR?: InputMaybe<Array<CarScalarWhereInput>>;
  contactId?: InputMaybe<IntFilter>;
  documentSerial?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  modelYear?: InputMaybe<StringNullableFilter>;
  officeId?: InputMaybe<IntFilter>;
  plate?: InputMaybe<StringFilter>;
  typeCode?: InputMaybe<StringNullableFilter>;
};

export type CarUncheckedUpdateInput = {
  contactId?: InputMaybe<IntFieldUpdateOperationsInput>;
  documentSerial?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  insurances?: InputMaybe<CarInsuranceUncheckedUpdateManyWithoutCarInput>;
  modelYear?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  officeId?: InputMaybe<IntFieldUpdateOperationsInput>;
  plate?: InputMaybe<StringFieldUpdateOperationsInput>;
  typeCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CarUncheckedUpdateManyWithoutContactInput = {
  connect?: InputMaybe<Array<CarWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CarCreateOrConnectWithoutContactInput>>;
  create?: InputMaybe<Array<CarCreateWithoutContactInput>>;
  createMany?: InputMaybe<CarCreateManyContactInputEnvelope>;
  delete?: InputMaybe<Array<CarWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CarScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CarWhereUniqueInput>>;
  set?: InputMaybe<Array<CarWhereUniqueInput>>;
  update?: InputMaybe<Array<CarUpdateWithWhereUniqueWithoutContactInput>>;
  updateMany?: InputMaybe<Array<CarUpdateManyWithWhereWithoutContactInput>>;
  upsert?: InputMaybe<Array<CarUpsertWithWhereUniqueWithoutContactInput>>;
};

export type CarUpdateManyMutationInput = {
  documentSerial?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelYear?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  plate?: InputMaybe<StringFieldUpdateOperationsInput>;
  typeCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CarUpdateManyWithWhereWithoutContactInput = {
  data: CarUpdateManyMutationInput;
  where: CarScalarWhereInput;
};

export type CarUpdateManyWithoutContactInput = {
  connect?: InputMaybe<Array<CarWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CarCreateOrConnectWithoutContactInput>>;
  create?: InputMaybe<Array<CarCreateWithoutContactInput>>;
  createMany?: InputMaybe<CarCreateManyContactInputEnvelope>;
  delete?: InputMaybe<Array<CarWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CarScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CarWhereUniqueInput>>;
  set?: InputMaybe<Array<CarWhereUniqueInput>>;
  update?: InputMaybe<Array<CarUpdateWithWhereUniqueWithoutContactInput>>;
  updateMany?: InputMaybe<Array<CarUpdateManyWithWhereWithoutContactInput>>;
  upsert?: InputMaybe<Array<CarUpsertWithWhereUniqueWithoutContactInput>>;
};

export type CarUpdateWithWhereUniqueWithoutContactInput = {
  data: CarUpdateWithoutContactInput;
  where: CarWhereUniqueInput;
};

export type CarUpdateWithoutContactInput = {
  documentSerial?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insurances?: InputMaybe<CarInsuranceUpdateManyWithoutCarInput>;
  modelYear?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  office?: InputMaybe<OfficeUpdateOneRequiredWithoutCarsInput>;
  plate?: InputMaybe<StringFieldUpdateOperationsInput>;
  typeCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CarUpsertWithWhereUniqueWithoutContactInput = {
  create: CarCreateWithoutContactInput;
  update: CarUpdateWithoutContactInput;
  where: CarWhereUniqueInput;
};

export type CarWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  officeId_plate?: InputMaybe<CarOfficeIdPlateCompoundUniqueInput>;
  plate?: InputMaybe<Scalars['String']>;
};

export type Contact = {
  __typename?: 'Contact';
  _count: ContactCount;
  birthdate?: Maybe<Scalars['DateTime']>;
  cars: Array<Car>;
  id: Scalars['ID'];
  name: Scalars['String'];
  office: Office;
  officeId: Scalars['Int'];
  phone?: Maybe<Scalars['String']>;
  surname: Scalars['String'];
  turkish_id?: Maybe<Scalars['String']>;
};

export type ContactCount = {
  __typename?: 'ContactCount';
  cars: Scalars['Int'];
};

export type ContactCreateManyOfficeInput = {
  birthdate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  surname: Scalars['String'];
  turkish_id?: InputMaybe<Scalars['String']>;
};

export type ContactCreateManyOfficeInputEnvelope = {
  data: Array<ContactCreateManyOfficeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ContactCreateNestedManyWithoutOfficeInput = {
  connect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContactCreateOrConnectWithoutOfficeInput>>;
  create?: InputMaybe<Array<ContactCreateWithoutOfficeInput>>;
  createMany?: InputMaybe<ContactCreateManyOfficeInputEnvelope>;
};

export type ContactCreateNestedOneWithoutCarsInput = {
  connect?: InputMaybe<ContactWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContactCreateOrConnectWithoutCarsInput>;
  create?: InputMaybe<ContactCreateWithoutCarsInput>;
};

export type ContactCreateOrConnectWithoutCarsInput = {
  create: ContactCreateWithoutCarsInput;
  where: ContactWhereUniqueInput;
};

export type ContactCreateOrConnectWithoutOfficeInput = {
  create: ContactCreateWithoutOfficeInput;
  where: ContactWhereUniqueInput;
};

export type ContactCreateWithoutCarsInput = {
  birthdate?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  office: OfficeCreateNestedOneWithoutContactsInput;
  phone?: InputMaybe<Scalars['String']>;
  surname: Scalars['String'];
  turkish_id?: InputMaybe<Scalars['String']>;
};

export type ContactCreateWithoutOfficeInput = {
  birthdate?: InputMaybe<Scalars['DateTime']>;
  cars?: InputMaybe<CarCreateNestedManyWithoutContactInput>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  surname: Scalars['String'];
  turkish_id?: InputMaybe<Scalars['String']>;
};

export type ContactScalarWhereInput = {
  AND?: InputMaybe<Array<ContactScalarWhereInput>>;
  NOT?: InputMaybe<Array<ContactScalarWhereInput>>;
  OR?: InputMaybe<Array<ContactScalarWhereInput>>;
  birthdate?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  officeId?: InputMaybe<IntFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  surname?: InputMaybe<StringFilter>;
  turkish_id?: InputMaybe<StringNullableFilter>;
};

export type ContactUncheckedUpdateInput = {
  birthdate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  cars?: InputMaybe<CarUncheckedUpdateManyWithoutContactInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  officeId?: InputMaybe<IntFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  surname?: InputMaybe<StringFieldUpdateOperationsInput>;
  turkish_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContactUpdateManyMutationInput = {
  birthdate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  surname?: InputMaybe<StringFieldUpdateOperationsInput>;
  turkish_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContactUpdateManyWithWhereWithoutOfficeInput = {
  data: ContactUpdateManyMutationInput;
  where: ContactScalarWhereInput;
};

export type ContactUpdateManyWithoutOfficeInput = {
  connect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContactCreateOrConnectWithoutOfficeInput>>;
  create?: InputMaybe<Array<ContactCreateWithoutOfficeInput>>;
  createMany?: InputMaybe<ContactCreateManyOfficeInputEnvelope>;
  delete?: InputMaybe<Array<ContactWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ContactScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  set?: InputMaybe<Array<ContactWhereUniqueInput>>;
  update?: InputMaybe<Array<ContactUpdateWithWhereUniqueWithoutOfficeInput>>;
  updateMany?: InputMaybe<Array<ContactUpdateManyWithWhereWithoutOfficeInput>>;
  upsert?: InputMaybe<Array<ContactUpsertWithWhereUniqueWithoutOfficeInput>>;
};

export type ContactUpdateWithWhereUniqueWithoutOfficeInput = {
  data: ContactUpdateWithoutOfficeInput;
  where: ContactWhereUniqueInput;
};

export type ContactUpdateWithoutOfficeInput = {
  birthdate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  cars?: InputMaybe<CarUpdateManyWithoutContactInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  surname?: InputMaybe<StringFieldUpdateOperationsInput>;
  turkish_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContactUpsertWithWhereUniqueWithoutOfficeInput = {
  create: ContactCreateWithoutOfficeInput;
  update: ContactUpdateWithoutOfficeInput;
  where: ContactWhereUniqueInput;
};

export type ContactWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type EnumInsuranceStateFieldUpdateOperationsInput = {
  set?: InputMaybe<InsuranceState>;
};

export type EnumInsuranceStateFilter = {
  equals?: InputMaybe<InsuranceState>;
  in?: InputMaybe<Array<InsuranceState>>;
  not?: InputMaybe<NestedEnumInsuranceStateFilter>;
  notIn?: InputMaybe<Array<InsuranceState>>;
};

export type Event = {
  __typename?: 'Event';
  endTime: Scalars['DateTime'];
  id: Scalars['ID'];
  note: Scalars['String'];
  office: Office;
  officeId: Scalars['Int'];
  startTime: Scalars['DateTime'];
  title: Scalars['String'];
};

export type EventCreateManyOfficeInput = {
  endTime: Scalars['DateTime'];
  id?: InputMaybe<Scalars['Int']>;
  note: Scalars['String'];
  startTime: Scalars['DateTime'];
  title: Scalars['String'];
};

export type EventCreateManyOfficeInputEnvelope = {
  data: Array<EventCreateManyOfficeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type EventCreateNestedManyWithoutOfficeInput = {
  connect?: InputMaybe<Array<EventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCreateOrConnectWithoutOfficeInput>>;
  create?: InputMaybe<Array<EventCreateWithoutOfficeInput>>;
  createMany?: InputMaybe<EventCreateManyOfficeInputEnvelope>;
};

export type EventCreateOrConnectWithoutOfficeInput = {
  create: EventCreateWithoutOfficeInput;
  where: EventWhereUniqueInput;
};

export type EventCreateWithoutOfficeInput = {
  endTime: Scalars['DateTime'];
  note: Scalars['String'];
  startTime: Scalars['DateTime'];
  title: Scalars['String'];
};

export type EventScalarWhereInput = {
  AND?: InputMaybe<Array<EventScalarWhereInput>>;
  NOT?: InputMaybe<Array<EventScalarWhereInput>>;
  OR?: InputMaybe<Array<EventScalarWhereInput>>;
  endTime?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  note?: InputMaybe<StringFilter>;
  officeId?: InputMaybe<IntFilter>;
  startTime?: InputMaybe<DateTimeFilter>;
  title?: InputMaybe<StringFilter>;
};

export type EventUncheckedUpdateInput = {
  endTime?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  note?: InputMaybe<StringFieldUpdateOperationsInput>;
  officeId?: InputMaybe<IntFieldUpdateOperationsInput>;
  startTime?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EventUpdateManyMutationInput = {
  endTime?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  note?: InputMaybe<StringFieldUpdateOperationsInput>;
  startTime?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EventUpdateManyWithWhereWithoutOfficeInput = {
  data: EventUpdateManyMutationInput;
  where: EventScalarWhereInput;
};

export type EventUpdateManyWithoutOfficeInput = {
  connect?: InputMaybe<Array<EventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCreateOrConnectWithoutOfficeInput>>;
  create?: InputMaybe<Array<EventCreateWithoutOfficeInput>>;
  createMany?: InputMaybe<EventCreateManyOfficeInputEnvelope>;
  delete?: InputMaybe<Array<EventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EventWhereUniqueInput>>;
  set?: InputMaybe<Array<EventWhereUniqueInput>>;
  update?: InputMaybe<Array<EventUpdateWithWhereUniqueWithoutOfficeInput>>;
  updateMany?: InputMaybe<Array<EventUpdateManyWithWhereWithoutOfficeInput>>;
  upsert?: InputMaybe<Array<EventUpsertWithWhereUniqueWithoutOfficeInput>>;
};

export type EventUpdateWithWhereUniqueWithoutOfficeInput = {
  data: EventUpdateWithoutOfficeInput;
  where: EventWhereUniqueInput;
};

export type EventUpdateWithoutOfficeInput = {
  endTime?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  note?: InputMaybe<StringFieldUpdateOperationsInput>;
  startTime?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EventUpsertWithWhereUniqueWithoutOfficeInput = {
  create: EventCreateWithoutOfficeInput;
  update: EventUpdateWithoutOfficeInput;
  where: EventWhereUniqueInput;
};

export type EventWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export enum InsuranceState {
  Rival = 'RIVAL',
  Unknown = 'UNKNOWN',
  Us = 'US'
}

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createCar: Car;
  createCarInsurance: CarInsurance;
  createContact: Contact;
  createEvent: Event;
  createUser: User;
  removeCar: Scalars['String'];
  removeCarInsurance?: Maybe<Scalars['String']>;
  removeContact?: Maybe<Scalars['String']>;
  removeEvent?: Maybe<Scalars['String']>;
  removeUser: User;
  updateCar?: Maybe<Scalars['String']>;
  updateCarInsurance?: Maybe<Scalars['String']>;
  updateContact?: Maybe<Scalars['String']>;
  updateEvent?: Maybe<Scalars['String']>;
  updateUser: User;
};


export type MutationCreateCarArgs = {
  createCarInput: CarCreateWithoutOfficeInput;
};


export type MutationCreateCarInsuranceArgs = {
  createCarInsuranceInput: CarInsuranceCreateInput;
};


export type MutationCreateContactArgs = {
  createContactInput: ContactCreateWithoutOfficeInput;
};


export type MutationCreateEventArgs = {
  createEventInput: EventCreateWithoutOfficeInput;
};


export type MutationCreateUserArgs = {
  createUserInput: UserCreateWithoutOfficeInput;
};


export type MutationRemoveCarArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveCarInsuranceArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveContactArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveEventArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveUserArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateCarArgs = {
  id: Scalars['Int'];
  updateCarInput: CarUncheckedUpdateInput;
};


export type MutationUpdateCarInsuranceArgs = {
  id: Scalars['Int'];
  updateCarInsuranceInput: CarInsuranceUncheckedUpdateInput;
};


export type MutationUpdateContactArgs = {
  id: Scalars['Int'];
  updateContactInput: ContactUncheckedUpdateInput;
};


export type MutationUpdateEventArgs = {
  id: Scalars['Int'];
  updateEventInput: EventUncheckedUpdateInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['Int'];
  updateUserInput: UserUncheckedUpdateInput;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedEnumInsuranceStateFilter = {
  equals?: InputMaybe<InsuranceState>;
  in?: InputMaybe<Array<InsuranceState>>;
  not?: InputMaybe<NestedEnumInsuranceStateFilter>;
  notIn?: InputMaybe<Array<InsuranceState>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type Office = {
  __typename?: 'Office';
  _count: OfficeCount;
  cars?: Maybe<Array<Car>>;
  contacts?: Maybe<Array<Contact>>;
  events?: Maybe<Array<Event>>;
  id: Scalars['ID'];
  officename: Scalars['String'];
  users?: Maybe<Array<User>>;
};

export type OfficeCount = {
  __typename?: 'OfficeCount';
  cars: Scalars['Int'];
  contacts: Scalars['Int'];
  events: Scalars['Int'];
  users: Scalars['Int'];
};

export type OfficeCreateNestedOneWithoutCarsInput = {
  connect?: InputMaybe<OfficeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OfficeCreateOrConnectWithoutCarsInput>;
  create?: InputMaybe<OfficeCreateWithoutCarsInput>;
};

export type OfficeCreateNestedOneWithoutContactsInput = {
  connect?: InputMaybe<OfficeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OfficeCreateOrConnectWithoutContactsInput>;
  create?: InputMaybe<OfficeCreateWithoutContactsInput>;
};

export type OfficeCreateOrConnectWithoutCarsInput = {
  create: OfficeCreateWithoutCarsInput;
  where: OfficeWhereUniqueInput;
};

export type OfficeCreateOrConnectWithoutContactsInput = {
  create: OfficeCreateWithoutContactsInput;
  where: OfficeWhereUniqueInput;
};

export type OfficeCreateWithoutCarsInput = {
  contacts?: InputMaybe<ContactCreateNestedManyWithoutOfficeInput>;
  events?: InputMaybe<EventCreateNestedManyWithoutOfficeInput>;
  officename: Scalars['String'];
  users?: InputMaybe<UserCreateNestedManyWithoutOfficeInput>;
};

export type OfficeCreateWithoutContactsInput = {
  cars?: InputMaybe<CarCreateNestedManyWithoutOfficeInput>;
  events?: InputMaybe<EventCreateNestedManyWithoutOfficeInput>;
  officename: Scalars['String'];
  users?: InputMaybe<UserCreateNestedManyWithoutOfficeInput>;
};

export type OfficeUpdateOneRequiredWithoutCarsInput = {
  connect?: InputMaybe<OfficeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OfficeCreateOrConnectWithoutCarsInput>;
  create?: InputMaybe<OfficeCreateWithoutCarsInput>;
  update?: InputMaybe<OfficeUpdateWithoutCarsInput>;
  upsert?: InputMaybe<OfficeUpsertWithoutCarsInput>;
};

export type OfficeUpdateWithoutCarsInput = {
  contacts?: InputMaybe<ContactUpdateManyWithoutOfficeInput>;
  events?: InputMaybe<EventUpdateManyWithoutOfficeInput>;
  officename?: InputMaybe<StringFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutOfficeInput>;
};

export type OfficeUpsertWithoutCarsInput = {
  create: OfficeCreateWithoutCarsInput;
  update: OfficeUpdateWithoutCarsInput;
};

export type OfficeWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  officename?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  car: Car;
  carInsurance: CarInsurance;
  carInsurances: Array<CarInsurance>;
  cars: Array<Car>;
  contact: Contact;
  contacts: Array<Contact>;
  contactsCount: Scalars['Int'];
  event: Event;
  events: Array<Event>;
  user: User;
  users: Array<User>;
};


export type QueryCarArgs = {
  id: Scalars['Int'];
};


export type QueryCarInsuranceArgs = {
  id: Scalars['Int'];
};


export type QueryContactArgs = {
  id: Scalars['Int'];
};


export type QueryContactsArgs = {
  page: Scalars['Int'];
};


export type QueryEventArgs = {
  id: Scalars['Int'];
};


export type QueryUserArgs = {
  userId: Scalars['Int'];
};

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  office: Office;
  officeId: Scalars['Int'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type UserCreateManyOfficeInput = {
  id?: InputMaybe<Scalars['Int']>;
  password: Scalars['String'];
  username: Scalars['String'];
};

export type UserCreateManyOfficeInputEnvelope = {
  data: Array<UserCreateManyOfficeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserCreateNestedManyWithoutOfficeInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutOfficeInput>>;
  create?: InputMaybe<Array<UserCreateWithoutOfficeInput>>;
  createMany?: InputMaybe<UserCreateManyOfficeInputEnvelope>;
};

export type UserCreateOrConnectWithoutOfficeInput = {
  create: UserCreateWithoutOfficeInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutOfficeInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type UserOfficeIdUsernameCompoundUniqueInput = {
  officeId: Scalars['Int'];
  username: Scalars['String'];
};

export type UserScalarWhereInput = {
  AND?: InputMaybe<Array<UserScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereInput>>;
  OR?: InputMaybe<Array<UserScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  officeId?: InputMaybe<IntFilter>;
  password?: InputMaybe<StringFilter>;
  username?: InputMaybe<StringFilter>;
};

export type UserUncheckedUpdateInput = {
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  officeId?: InputMaybe<IntFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserUpdateManyMutationInput = {
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserUpdateManyWithWhereWithoutOfficeInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithoutOfficeInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutOfficeInput>>;
  create?: InputMaybe<Array<UserCreateWithoutOfficeInput>>;
  createMany?: InputMaybe<UserCreateManyOfficeInputEnvelope>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutOfficeInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutOfficeInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutOfficeInput>>;
};

export type UserUpdateWithWhereUniqueWithoutOfficeInput = {
  data: UserUpdateWithoutOfficeInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithoutOfficeInput = {
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserUpsertWithWhereUniqueWithoutOfficeInput = {
  create: UserCreateWithoutOfficeInput;
  update: UserUpdateWithoutOfficeInput;
  where: UserWhereUniqueInput;
};

export type UserWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  officeId_username?: InputMaybe<UserOfficeIdUsernameCompoundUniqueInput>;
};

export type CreateContactMutationVariables = Exact<{
  input: ContactCreateWithoutOfficeInput;
}>;


export type CreateContactMutation = { __typename?: 'Mutation', createContact: { __typename?: 'Contact', id: string } };

export type GetContactsPagedQueryVariables = Exact<{
  page: Scalars['Int'];
}>;


export type GetContactsPagedQuery = { __typename?: 'Query', contactsCount: number, contacts: Array<{ __typename?: 'Contact', name: string, surname: string, id: string }> };

export type GetOneContactQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetOneContactQuery = { __typename?: 'Query', contact: { __typename?: 'Contact', id: string, name: string, surname: string, turkish_id?: string | null, phone?: string | null, birthdate?: any | null, cars: Array<{ __typename?: 'Car', id: string, plate: string, carInsurances: Array<{ __typename?: 'CarInsurance', id: string, title: string, startTime?: any | null, endTime?: any | null, state: InsuranceState }> }> } };

export type RemoveContactMutationVariables = Exact<{
  input: Scalars['Int'];
}>;


export type RemoveContactMutation = { __typename?: 'Mutation', removeContact?: string | null };

export type UpdateContactMutationVariables = Exact<{
  input: ContactUncheckedUpdateInput;
  id: Scalars['Int'];
}>;


export type UpdateContactMutation = { __typename?: 'Mutation', updateContact?: string | null };

export type CreateEventMutationVariables = Exact<{
  input: EventCreateWithoutOfficeInput;
}>;


export type CreateEventMutation = { __typename?: 'Mutation', createEvent: { __typename?: 'Event', id: string } };

export type DeleteEventMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteEventMutation = { __typename?: 'Mutation', removeEvent?: string | null };

export type GetAllEventsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllEventsQuery = { __typename?: 'Query', events: Array<{ __typename?: 'Event', id: string, title: string, startTime: any, endTime: any, note: string }>, carInsurances: Array<{ __typename?: 'CarInsurance', id: string, title: string, startTime?: any | null, endTime?: any | null, car: { __typename?: 'Car', id: string, plate: string, contact: { __typename?: 'Contact', id: string, name: string, surname: string } } }> };

export type UpdateEventMutationVariables = Exact<{
  input: EventUncheckedUpdateInput;
  id: Scalars['Int'];
}>;


export type UpdateEventMutation = { __typename?: 'Mutation', updateEvent?: string | null };

export type CreateCarInsuranceMutationVariables = Exact<{
  input: CarInsuranceCreateInput;
}>;


export type CreateCarInsuranceMutation = { __typename?: 'Mutation', createCarInsurance: { __typename?: 'CarInsurance', id: string, car: { __typename?: 'Car', contact: { __typename?: 'Contact', id: string } } } };

export type GetCarInsurancesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCarInsurancesQuery = { __typename?: 'Query', carInsurances: Array<{ __typename?: 'CarInsurance', id: string, title: string, startTime?: any | null, endTime?: any | null, car: { __typename?: 'Car', id: string, plate: string, contact: { __typename?: 'Contact', id: string, name: string, surname: string } } }> };

export type GetOneCarInsuranceQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetOneCarInsuranceQuery = { __typename?: 'Query', carInsurance: { __typename?: 'CarInsurance', id: string, title: string, state: InsuranceState, startTime?: any | null, endTime?: any | null, pdf_url?: string | null, car: { __typename?: 'Car', id: string, plate: string, contact: { __typename?: 'Contact', id: string, name: string, surname: string } } } };

export type RemoveCarInsuranceMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveCarInsuranceMutation = { __typename?: 'Mutation', removeCarInsurance?: string | null };

export type UpdateCarInsuranceMutationVariables = Exact<{
  input: CarInsuranceUncheckedUpdateInput;
  id: Scalars['Int'];
}>;


export type UpdateCarInsuranceMutation = { __typename?: 'Mutation', updateCarInsurance?: string | null };

export type CreateCarMutationVariables = Exact<{
  input: CarCreateWithoutOfficeInput;
}>;


export type CreateCarMutation = { __typename?: 'Mutation', createCar: { __typename?: 'Car', plate: string, id: string, contact: { __typename?: 'Contact', id: string } } };

export type GetOneCarQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetOneCarQuery = { __typename?: 'Query', car: { __typename?: 'Car', id: string, plate: string, documentSerial?: string | null, typeCode?: string | null, modelYear?: string | null, contact: { __typename?: 'Contact', id: string, name: string, surname: string }, carInsurances: Array<{ __typename?: 'CarInsurance', id: string, title: string, state: InsuranceState, startTime?: any | null, endTime?: any | null }> } };

export type RemoveCarMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveCarMutation = { __typename?: 'Mutation', removeCar: string };

export type UpdateCarMutationVariables = Exact<{
  input: CarUncheckedUpdateInput;
  id: Scalars['Int'];
}>;


export type UpdateCarMutation = { __typename?: 'Mutation', updateCar?: string | null };

export type CreateUserMutationVariables = Exact<{
  input: UserCreateWithoutOfficeInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', username: string } };

export type GetOneUserQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetOneUserQuery = { __typename?: 'Query', user: { __typename?: 'User', username: string } };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, username: string }> };

export type UpdateUserMutationVariables = Exact<{
  input: UserUncheckedUpdateInput;
  id: Scalars['Int'];
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', username: string } };


export const CreateContactDocument = `
    mutation CreateContact($input: ContactCreateWithoutOfficeInput!) {
  createContact(createContactInput: $input) {
    id
  }
}
    `;
export const useCreateContactMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateContactMutation, TError, CreateContactMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreateContactMutation, TError, CreateContactMutationVariables, TContext>(
      ['CreateContact'],
      (variables?: CreateContactMutationVariables) => fetcher<CreateContactMutation, CreateContactMutationVariables>(client, CreateContactDocument, variables, headers)(),
      options
    );
export const GetContactsPagedDocument = `
    query GetContactsPaged($page: Int!) {
  contacts(page: $page) {
    name
    surname
    id
  }
  contactsCount
}
    `;
export const useGetContactsPagedQuery = <
      TData = GetContactsPagedQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetContactsPagedQueryVariables,
      options?: UseQueryOptions<GetContactsPagedQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetContactsPagedQuery, TError, TData>(
      ['GetContactsPaged', variables],
      fetcher<GetContactsPagedQuery, GetContactsPagedQueryVariables>(client, GetContactsPagedDocument, variables, headers),
      options
    );
export const useInfiniteGetContactsPagedQuery = <
      TData = GetContactsPagedQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetContactsPagedQueryVariables,
      client: GraphQLClient,
      variables: GetContactsPagedQueryVariables,
      options?: UseInfiniteQueryOptions<GetContactsPagedQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetContactsPagedQuery, TError, TData>(
      ['GetContactsPaged.infinite', variables],
      (metaData) => fetcher<GetContactsPagedQuery, GetContactsPagedQueryVariables>(client, GetContactsPagedDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );

export const GetOneContactDocument = `
    query GetOneContact($id: Int!) {
  contact(id: $id) {
    id
    name
    surname
    turkish_id
    phone
    birthdate
    cars {
      id
      plate
      carInsurances {
        id
        title
        startTime
        endTime
        state
      }
    }
  }
}
    `;
export const useGetOneContactQuery = <
      TData = GetOneContactQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetOneContactQueryVariables,
      options?: UseQueryOptions<GetOneContactQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetOneContactQuery, TError, TData>(
      ['GetOneContact', variables],
      fetcher<GetOneContactQuery, GetOneContactQueryVariables>(client, GetOneContactDocument, variables, headers),
      options
    );
export const useInfiniteGetOneContactQuery = <
      TData = GetOneContactQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetOneContactQueryVariables,
      client: GraphQLClient,
      variables: GetOneContactQueryVariables,
      options?: UseInfiniteQueryOptions<GetOneContactQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetOneContactQuery, TError, TData>(
      ['GetOneContact.infinite', variables],
      (metaData) => fetcher<GetOneContactQuery, GetOneContactQueryVariables>(client, GetOneContactDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );

export const RemoveContactDocument = `
    mutation RemoveContact($input: Int!) {
  removeContact(id: $input)
}
    `;
export const useRemoveContactMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<RemoveContactMutation, TError, RemoveContactMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<RemoveContactMutation, TError, RemoveContactMutationVariables, TContext>(
      ['RemoveContact'],
      (variables?: RemoveContactMutationVariables) => fetcher<RemoveContactMutation, RemoveContactMutationVariables>(client, RemoveContactDocument, variables, headers)(),
      options
    );
export const UpdateContactDocument = `
    mutation UpdateContact($input: ContactUncheckedUpdateInput!, $id: Int!) {
  updateContact(updateContactInput: $input, id: $id)
}
    `;
export const useUpdateContactMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateContactMutation, TError, UpdateContactMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UpdateContactMutation, TError, UpdateContactMutationVariables, TContext>(
      ['UpdateContact'],
      (variables?: UpdateContactMutationVariables) => fetcher<UpdateContactMutation, UpdateContactMutationVariables>(client, UpdateContactDocument, variables, headers)(),
      options
    );
export const CreateEventDocument = `
    mutation CreateEvent($input: EventCreateWithoutOfficeInput!) {
  createEvent(createEventInput: $input) {
    id
  }
}
    `;
export const useCreateEventMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateEventMutation, TError, CreateEventMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreateEventMutation, TError, CreateEventMutationVariables, TContext>(
      ['CreateEvent'],
      (variables?: CreateEventMutationVariables) => fetcher<CreateEventMutation, CreateEventMutationVariables>(client, CreateEventDocument, variables, headers)(),
      options
    );
export const DeleteEventDocument = `
    mutation DeleteEvent($id: Int!) {
  removeEvent(id: $id)
}
    `;
export const useDeleteEventMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteEventMutation, TError, DeleteEventMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DeleteEventMutation, TError, DeleteEventMutationVariables, TContext>(
      ['DeleteEvent'],
      (variables?: DeleteEventMutationVariables) => fetcher<DeleteEventMutation, DeleteEventMutationVariables>(client, DeleteEventDocument, variables, headers)(),
      options
    );
export const GetAllEventsDocument = `
    query GetAllEvents {
  events {
    id
    title
    startTime
    endTime
    note
  }
  carInsurances {
    id
    title
    startTime
    endTime
    car {
      id
      plate
      contact {
        id
        name
        surname
      }
    }
  }
}
    `;
export const useGetAllEventsQuery = <
      TData = GetAllEventsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetAllEventsQueryVariables,
      options?: UseQueryOptions<GetAllEventsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetAllEventsQuery, TError, TData>(
      variables === undefined ? ['GetAllEvents'] : ['GetAllEvents', variables],
      fetcher<GetAllEventsQuery, GetAllEventsQueryVariables>(client, GetAllEventsDocument, variables, headers),
      options
    );
export const useInfiniteGetAllEventsQuery = <
      TData = GetAllEventsQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetAllEventsQueryVariables,
      client: GraphQLClient,
      variables?: GetAllEventsQueryVariables,
      options?: UseInfiniteQueryOptions<GetAllEventsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetAllEventsQuery, TError, TData>(
      variables === undefined ? ['GetAllEvents.infinite'] : ['GetAllEvents.infinite', variables],
      (metaData) => fetcher<GetAllEventsQuery, GetAllEventsQueryVariables>(client, GetAllEventsDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );

export const UpdateEventDocument = `
    mutation UpdateEvent($input: EventUncheckedUpdateInput!, $id: Int!) {
  updateEvent(updateEventInput: $input, id: $id)
}
    `;
export const useUpdateEventMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateEventMutation, TError, UpdateEventMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UpdateEventMutation, TError, UpdateEventMutationVariables, TContext>(
      ['UpdateEvent'],
      (variables?: UpdateEventMutationVariables) => fetcher<UpdateEventMutation, UpdateEventMutationVariables>(client, UpdateEventDocument, variables, headers)(),
      options
    );
export const CreateCarInsuranceDocument = `
    mutation CreateCarInsurance($input: CarInsuranceCreateInput!) {
  createCarInsurance(createCarInsuranceInput: $input) {
    id
    car {
      contact {
        id
      }
    }
  }
}
    `;
export const useCreateCarInsuranceMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateCarInsuranceMutation, TError, CreateCarInsuranceMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreateCarInsuranceMutation, TError, CreateCarInsuranceMutationVariables, TContext>(
      ['CreateCarInsurance'],
      (variables?: CreateCarInsuranceMutationVariables) => fetcher<CreateCarInsuranceMutation, CreateCarInsuranceMutationVariables>(client, CreateCarInsuranceDocument, variables, headers)(),
      options
    );
export const GetCarInsurancesDocument = `
    query GetCarInsurances {
  carInsurances {
    id
    title
    startTime
    endTime
    car {
      id
      plate
      contact {
        id
        name
        surname
      }
    }
  }
}
    `;
export const useGetCarInsurancesQuery = <
      TData = GetCarInsurancesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetCarInsurancesQueryVariables,
      options?: UseQueryOptions<GetCarInsurancesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetCarInsurancesQuery, TError, TData>(
      variables === undefined ? ['GetCarInsurances'] : ['GetCarInsurances', variables],
      fetcher<GetCarInsurancesQuery, GetCarInsurancesQueryVariables>(client, GetCarInsurancesDocument, variables, headers),
      options
    );
export const useInfiniteGetCarInsurancesQuery = <
      TData = GetCarInsurancesQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetCarInsurancesQueryVariables,
      client: GraphQLClient,
      variables?: GetCarInsurancesQueryVariables,
      options?: UseInfiniteQueryOptions<GetCarInsurancesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetCarInsurancesQuery, TError, TData>(
      variables === undefined ? ['GetCarInsurances.infinite'] : ['GetCarInsurances.infinite', variables],
      (metaData) => fetcher<GetCarInsurancesQuery, GetCarInsurancesQueryVariables>(client, GetCarInsurancesDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );

export const GetOneCarInsuranceDocument = `
    query GetOneCarInsurance($id: Int!) {
  carInsurance(id: $id) {
    id
    title
    state
    startTime
    endTime
    pdf_url
    car {
      id
      plate
      contact {
        id
        name
        surname
      }
    }
  }
}
    `;
export const useGetOneCarInsuranceQuery = <
      TData = GetOneCarInsuranceQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetOneCarInsuranceQueryVariables,
      options?: UseQueryOptions<GetOneCarInsuranceQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetOneCarInsuranceQuery, TError, TData>(
      ['GetOneCarInsurance', variables],
      fetcher<GetOneCarInsuranceQuery, GetOneCarInsuranceQueryVariables>(client, GetOneCarInsuranceDocument, variables, headers),
      options
    );
export const useInfiniteGetOneCarInsuranceQuery = <
      TData = GetOneCarInsuranceQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetOneCarInsuranceQueryVariables,
      client: GraphQLClient,
      variables: GetOneCarInsuranceQueryVariables,
      options?: UseInfiniteQueryOptions<GetOneCarInsuranceQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetOneCarInsuranceQuery, TError, TData>(
      ['GetOneCarInsurance.infinite', variables],
      (metaData) => fetcher<GetOneCarInsuranceQuery, GetOneCarInsuranceQueryVariables>(client, GetOneCarInsuranceDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );

export const RemoveCarInsuranceDocument = `
    mutation RemoveCarInsurance($id: Int!) {
  removeCarInsurance(id: $id)
}
    `;
export const useRemoveCarInsuranceMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<RemoveCarInsuranceMutation, TError, RemoveCarInsuranceMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<RemoveCarInsuranceMutation, TError, RemoveCarInsuranceMutationVariables, TContext>(
      ['RemoveCarInsurance'],
      (variables?: RemoveCarInsuranceMutationVariables) => fetcher<RemoveCarInsuranceMutation, RemoveCarInsuranceMutationVariables>(client, RemoveCarInsuranceDocument, variables, headers)(),
      options
    );
export const UpdateCarInsuranceDocument = `
    mutation UpdateCarInsurance($input: CarInsuranceUncheckedUpdateInput!, $id: Int!) {
  updateCarInsurance(updateCarInsuranceInput: $input, id: $id)
}
    `;
export const useUpdateCarInsuranceMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateCarInsuranceMutation, TError, UpdateCarInsuranceMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UpdateCarInsuranceMutation, TError, UpdateCarInsuranceMutationVariables, TContext>(
      ['UpdateCarInsurance'],
      (variables?: UpdateCarInsuranceMutationVariables) => fetcher<UpdateCarInsuranceMutation, UpdateCarInsuranceMutationVariables>(client, UpdateCarInsuranceDocument, variables, headers)(),
      options
    );
export const CreateCarDocument = `
    mutation CreateCar($input: CarCreateWithoutOfficeInput!) {
  createCar(createCarInput: $input) {
    plate
    id
    contact {
      id
    }
  }
}
    `;
export const useCreateCarMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateCarMutation, TError, CreateCarMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreateCarMutation, TError, CreateCarMutationVariables, TContext>(
      ['CreateCar'],
      (variables?: CreateCarMutationVariables) => fetcher<CreateCarMutation, CreateCarMutationVariables>(client, CreateCarDocument, variables, headers)(),
      options
    );
export const GetOneCarDocument = `
    query GetOneCar($id: Int!) {
  car(id: $id) {
    id
    plate
    documentSerial
    typeCode
    modelYear
    contact {
      id
      name
      surname
    }
    carInsurances {
      id
      title
      state
      startTime
      endTime
    }
  }
}
    `;
export const useGetOneCarQuery = <
      TData = GetOneCarQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetOneCarQueryVariables,
      options?: UseQueryOptions<GetOneCarQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetOneCarQuery, TError, TData>(
      ['GetOneCar', variables],
      fetcher<GetOneCarQuery, GetOneCarQueryVariables>(client, GetOneCarDocument, variables, headers),
      options
    );
export const useInfiniteGetOneCarQuery = <
      TData = GetOneCarQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetOneCarQueryVariables,
      client: GraphQLClient,
      variables: GetOneCarQueryVariables,
      options?: UseInfiniteQueryOptions<GetOneCarQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetOneCarQuery, TError, TData>(
      ['GetOneCar.infinite', variables],
      (metaData) => fetcher<GetOneCarQuery, GetOneCarQueryVariables>(client, GetOneCarDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );

export const RemoveCarDocument = `
    mutation RemoveCar($id: Int!) {
  removeCar(id: $id)
}
    `;
export const useRemoveCarMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<RemoveCarMutation, TError, RemoveCarMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<RemoveCarMutation, TError, RemoveCarMutationVariables, TContext>(
      ['RemoveCar'],
      (variables?: RemoveCarMutationVariables) => fetcher<RemoveCarMutation, RemoveCarMutationVariables>(client, RemoveCarDocument, variables, headers)(),
      options
    );
export const UpdateCarDocument = `
    mutation UpdateCar($input: CarUncheckedUpdateInput!, $id: Int!) {
  updateCar(updateCarInput: $input, id: $id)
}
    `;
export const useUpdateCarMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateCarMutation, TError, UpdateCarMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UpdateCarMutation, TError, UpdateCarMutationVariables, TContext>(
      ['UpdateCar'],
      (variables?: UpdateCarMutationVariables) => fetcher<UpdateCarMutation, UpdateCarMutationVariables>(client, UpdateCarDocument, variables, headers)(),
      options
    );
export const CreateUserDocument = `
    mutation CreateUser($input: UserCreateWithoutOfficeInput!) {
  createUser(createUserInput: $input) {
    username
  }
}
    `;
export const useCreateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateUserMutation, TError, CreateUserMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreateUserMutation, TError, CreateUserMutationVariables, TContext>(
      ['CreateUser'],
      (variables?: CreateUserMutationVariables) => fetcher<CreateUserMutation, CreateUserMutationVariables>(client, CreateUserDocument, variables, headers)(),
      options
    );
export const GetOneUserDocument = `
    query GetOneUser($id: Int!) {
  user(userId: $id) {
    username
  }
}
    `;
export const useGetOneUserQuery = <
      TData = GetOneUserQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetOneUserQueryVariables,
      options?: UseQueryOptions<GetOneUserQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetOneUserQuery, TError, TData>(
      ['GetOneUser', variables],
      fetcher<GetOneUserQuery, GetOneUserQueryVariables>(client, GetOneUserDocument, variables, headers),
      options
    );
export const useInfiniteGetOneUserQuery = <
      TData = GetOneUserQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetOneUserQueryVariables,
      client: GraphQLClient,
      variables: GetOneUserQueryVariables,
      options?: UseInfiniteQueryOptions<GetOneUserQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetOneUserQuery, TError, TData>(
      ['GetOneUser.infinite', variables],
      (metaData) => fetcher<GetOneUserQuery, GetOneUserQueryVariables>(client, GetOneUserDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );

export const GetUsersDocument = `
    query GetUsers {
  users {
    id
    username
  }
}
    `;
export const useGetUsersQuery = <
      TData = GetUsersQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetUsersQueryVariables,
      options?: UseQueryOptions<GetUsersQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetUsersQuery, TError, TData>(
      variables === undefined ? ['GetUsers'] : ['GetUsers', variables],
      fetcher<GetUsersQuery, GetUsersQueryVariables>(client, GetUsersDocument, variables, headers),
      options
    );
export const useInfiniteGetUsersQuery = <
      TData = GetUsersQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetUsersQueryVariables,
      client: GraphQLClient,
      variables?: GetUsersQueryVariables,
      options?: UseInfiniteQueryOptions<GetUsersQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetUsersQuery, TError, TData>(
      variables === undefined ? ['GetUsers.infinite'] : ['GetUsers.infinite', variables],
      (metaData) => fetcher<GetUsersQuery, GetUsersQueryVariables>(client, GetUsersDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );

export const UpdateUserDocument = `
    mutation UpdateUser($input: UserUncheckedUpdateInput!, $id: Int!) {
  updateUser(updateUserInput: $input, id: $id) {
    username
  }
}
    `;
export const useUpdateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>(
      ['UpdateUser'],
      (variables?: UpdateUserMutationVariables) => fetcher<UpdateUserMutation, UpdateUserMutationVariables>(client, UpdateUserDocument, variables, headers)(),
      options
    );