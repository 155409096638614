import React, { FormEvent, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCreateCarMutation } from '../../generated/graphql';
import graphqlRequestClient from '../../Helpers/graphql-client';



const CarsNew = ({ contact }: any) => {

  const navigate = useNavigate();

  const { mutate } = useCreateCarMutation(graphqlRequestClient, {
    onSuccess: (res) => {
      navigate('/app/contacts/' + res.createCar.contact.id)
      console.log("test")
      toast.success('Test', {
        position: toast.POSITION.TOP_RIGHT
      })
    }

  });

  const [state, setState] = useState({
    plate: "",
    documentSerial: "",
    typeCode: "",
    modelYear: "",
  })


  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    mutate({
      input: {
        plate: state.plate,
        documentSerial: state.documentSerial,
        typeCode: state.typeCode,
        modelYear: state.modelYear,
        contact: {
          connect: {
            id: parseInt(contact.id)
          }
        }
      }
    });
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Plaka</Form.Label>
          <Form.Control size='lg' value={state.plate} onChange={e => setState({...state, plate: e.target.value})} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Belge Seri No</Form.Label>
          <Form.Control size='lg' value={state.documentSerial} onChange={e => setState({...state, documentSerial: e.target.value})} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Araç Tipi Kodu</Form.Label>
          <Form.Control size='lg' value={state.typeCode} onChange={e => setState({...state, typeCode: e.target.value})} />
        </Form.Group>
        <Form.Group className='mb-4'>
          <Form.Label>Model Yılı</Form.Label>
          <Form.Control size='lg' value={state.modelYear} onChange={e => setState({...state, modelYear: e.target.value})} />
        </Form.Group>

        <div className='d-grid gap-3 d-md-block'>
        <Button className='py-3 py-md-2' type="submit"> Yeni Araç Oluştur </Button>
        </div>
      </Form>
    
    
    </>
  )


}


export default CarsNew;