//@ts-nocheck
import { Calendar, dateFnsLocalizer, Event } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import tr from 'date-fns/locale/tr'
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { GetAllEventsQuery, useGetAllEventsQuery, useGetCarInsurancesQuery } from '../generated/graphql';
import graphqlRequestClient from '../Helpers/graphql-client';
import { Button, Modal, ModalBody } from 'react-bootstrap';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'

const locales = {
  "tr": tr
}


const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales
});


const EventsIndex = () => {

  const navigate = useNavigate();

  const { data, isLoading } = useGetAllEventsQuery(graphqlRequestClient, {

  })

  const [state, setState] = useState({
    showModal: false,
    selectedEvent: {
      id: '',
      title: '',
      start: new Date(),
      end: new Date(),
      contact: {},
      property: {},
      insurance: {}
    }
  })

  const handleSelectEvent = (event: any) => {
    //if (event.type == 'insurance-end')
    setState({ ...state, showModal: true, selectedEvent: event })
  }

  const formatData = (data: GetAllEventsQuery) => {
    return [
      ...data?.events.map(event => {
        return {
          title: event.title,
          start: new Date(event.startTime),
          end: new Date(event.endTime),
          note: event.note,
          type: 'event',
          id: event.id,
        }
      }),
      ...data?.carInsurances.map(insurance => {
        return {
          title: insurance.car.contact.name + ' ' + insurance.car.contact.surname + ' - ' + insurance.title + ' bitiş',
          start: new Date(insurance.endTime),
          end: new Date(insurance.endTime),
          allDay: true,
          contact: insurance.car.contact,
          property: insurance.car,
          insurance: {
            title: insurance.title,
            id: insurance.id
          },
          type: 'insurance-end',
          id: insurance.id
        }
      }),

    ]
  }

  const EventWrapper = ({ event, children }) => {
    const { title, className } = children.props;

    return (
      <div
        onClick={e => handleSelectEvent(event)}
        title={title}
        className={'text-white border-2 p-1 cursor-pointer ' + (event.type == 'insurance-end' ? 'bg-blue-700' : 'bg-green-700')}
      >
        {children.props.children}
      </div>
    )
  }



  return (
    <>
      <h2> Takvim </h2>
      <Link to="/app/events/new" className='btn btn-success'> Yeni Etkinlik Oluştur </Link>
      <div style={{ height: '100vh' }}>
        {isLoading ? 'Loading' :
          <Calendar
            localizer={localizer}
            events={formatData(data)}
            culture={"tr"}
            views={['month', 'agenda']}
            onSelectEvent={handleSelectEvent}
            components={{
              eventWrapper: EventWrapper
            }}
            messages={{
              allDay: 'Tüm Gün',
              agenda: 'Ajanda',
              date: 'Tarih',
              day: 'Gün',
              event: 'Hatırlatıcı',
              month: 'Aylık',
              next: 'Sonraki',
              noEventsInRange: 'Hatırlatıcı Bulunamadı',
              previous: 'Önceki',
              showMore: (number) => `${number} tane daha...`,
              time: 'Saat',
              today: 'Bugün',
              tomorrow: 'Yarın',
              week: 'Hafta',
              work_week: 'İş Günleri',
              yesterday: 'Dün'
            }}
          />}
      </div>

      <Modal
        onHide={() => setState({ ...state, showModal: false })}
        show={state.showModal}
      >
        <Modal.Header>
          <Modal.Title>
            {state.selectedEvent.title}
          </Modal.Title>
        </Modal.Header>
        {state.selectedEvent.type == 'insurance-end' ?
          <Modal.Body>
            <p> İlgili Müşteri: <Link to={'/app/contacts/' + state.selectedEvent.contact.id}>{state.selectedEvent.contact.name}</Link> </p>
            <p> İlgili Araç: <Link to={'/app/properties/cars/' + state.selectedEvent.property.id}>{state.selectedEvent.property.plate}</Link></p>
            <p> İlgili Sigorta Poliçesi: <Link to={'/app/car-insurances/' + state.selectedEvent.insurance.id}>{state.selectedEvent.insurance.title}</Link> </p>
          </Modal.Body>
          :
          <>
            <ModalBody>
              <p> Başlangıç Zamanı: {format(new Date(state.selectedEvent.start), 'p -- dd LLLL yyyy', { locale: tr })} </p>
              <p> Bitiş Zamanı: {format(new Date(state.selectedEvent.end), 'p -- dd LLLL yyyy', { locale: tr })} </p>
              <p>{format(new Date(state.selectedEvent.start), "yyyy-MM-dd'T'kk':'mm")}</p>
              <p> Açıklama: {state.selectedEvent.note} </p>
            </ModalBody>
            <Modal.Footer>
              <Button
                variant="warning"
                onClick={e => navigate('/app/events/update/' + state.selectedEvent.id, { state: { event: state.selectedEvent } })}
              > Düzenle </Button>
            </Modal.Footer>
          </>
        }

      </Modal>

    </>
  )



}

export default EventsIndex;

