import { format, formatDistanceStrict } from 'date-fns';
import { tr } from 'date-fns/locale';
import React, { useState } from 'react';
import { Breadcrumb, Button, Form, Modal } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetOneCarInsuranceQuery, useRemoveCarInsuranceMutation, useUpdateCarInsuranceMutation } from '../../generated/graphql';
import { useAuth } from '../../Helpers/authContext';
import graphqlRequestClient from '../../Helpers/graphql-client';
import urls from '../../Helpers/urls';


const CarInsuranceView = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const urlParams = useParams();
  const id = Number(urlParams.id)

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalInput, setModalInput] = useState('');
  const client = useQueryClient()


  const { data } = useGetOneCarInsuranceQuery(graphqlRequestClient, { id })
  const { mutate: removeCarInsuranceMut } = useRemoveCarInsuranceMutation(graphqlRequestClient, {
    onSuccess: res => {
      toast.success('Araç sigortası başarıyla silindi.')
      navigate('/app/properties/cars/' + data?.carInsurance.car.id)
    }
  })
  const { mutate: update } = useUpdateCarInsuranceMutation(graphqlRequestClient, {
    onSuccess: () => {
      client.invalidateQueries('GetOneCarInsurance')
    },
    onSettled: () => {
      toast.success('Araç sigortası bir sonraki yıl için başarıyla yenilendi.')
    }
  })

  const handleDownloadPdf = () => {
    fetch(urls.car_pdf_get(data?.carInsurance.id!), {
      mode: 'cors',
      headers: [
        ['Authorization', 'Bearer ' + user.token],
        ['Content-Type', 'application/pdf']
      ]
    }).then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.target = 'police.pdf'
        link.setAttribute(
          'download',
          `police.pdf`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        //@ts-ignore
        link.parentNode.removeChild(link);
      });

    /*then(response => {
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = 'employees.pdf';
          a.click();
        });
        //window.location.href = response.url;
  });
  */
  }

  const handleUpdateCarInsurance = () => {
    navigate('/app/car-insurances/update/' + data?.carInsurance.id, { state: { insurance: data?.carInsurance } })
  }

  const handleSendPdfEmail = () => {

  }

  const handleSendPdfWP = () => {
    const url = 'https://wa.me/905345180081?text=test'
    window.open(url, '_blank')?.focus()
  }

  const handleRenew = (e: any) => {
    update({
      id: parseInt(data?.carInsurance.id!),
      input: {
        startTime: {
          set: format(new Date(data?.carInsurance.startTime).setFullYear(new Date(data?.carInsurance.startTime).getFullYear() + 1), 'yyyy-MM-dd')
        },
        endTime: {
          set: format(new Date(data?.carInsurance.endTime).setFullYear(new Date(data?.carInsurance.startTime).getFullYear() + 1), 'yyyy-MM-dd')
        }
      }
    })
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to={'/app/contacts'}>Müşteriler</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to={'/app/contacts/' + data?.carInsurance.car.contact.id}>{data?.carInsurance.car.contact.name + ' ' + data?.carInsurance.car.contact.surname}</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to={'/app/properties/cars/'+data?.carInsurance.car.id}>{data?.carInsurance.car.plate}</Link></Breadcrumb.Item>
        <Breadcrumb.Item active>{data?.carInsurance.title}</Breadcrumb.Item>
      </Breadcrumb>
      <p> Araç Sigortası </p>
      <h2 className='display-3 text-center text-md-start'> {data?.carInsurance.car.plate}: {data?.carInsurance.title} </h2>
      <p className='mt-4'> Sigorta Adı: {data?.carInsurance.title} </p>
      <p> Sigortası yapıldı mı: {data?.carInsurance.state == 'US' ? 'Bizde' : data?.carInsurance.state == 'UNKNOWN' ? 'Bilgi yok' : 'Rakipte'} </p>
      <p> Başlama Tarihi: {data?.carInsurance.startTime ?
        format(new Date(data?.carInsurance.startTime), 'dd LLLL yyyy', { locale: tr }) : '-'
      } </p>
      <p> Bitiş Tarihi: {data?.carInsurance.endTime ?
        format(new Date(data?.carInsurance.endTime), 'dd LLLL yyyy', { locale: tr }) : '-'
      } </p>
      {/*
      <p> Bitmesine Kalan Süre: {formatDistanceStrict(
          new Date(data?.carInsurance.startTime),
          new Date(data?.carInsurance.endTime),
          { locale: tr }
        )} </p>
      */}
      <p> Araç: <Link to={"/app/properties/cars/" + data?.carInsurance.car.id}>{data?.carInsurance.car.plate}</Link> </p>
      <p> Müşteri: <Link to={"/app/contacts/" + data?.carInsurance.car.contact.id}>{data?.carInsurance.car.contact.name + ' ' + data?.carInsurance.car.contact.surname}</Link> </p>
      <p> PDF Belgesi:  {data?.carInsurance.pdf_url ?
        <button onClick={handleDownloadPdf}><i className="fa-solid fa-file-pdf fs-1"></i></button>
        : 'Pdf Yok, Eklemek İçin Düzenleden Ekleyiniz.'} </p>




      {/**
      <Button onClick={handleSendPdfEmail} className='my-1'>PDFi e-posta olarak gönder</Button><br/>
      <Button onClick={handleSendPdfWP}>PDFi WhatsApp üzerinden gönder</Button>
      */}
      <div className='d-grid gap-2 d-md-block mt-3'>
        <Button className='py-3 py-md-2' variant="warning" onClick={handleUpdateCarInsurance}> Sigortayı Düzenle </Button>
        <Button className='py-3 py-md-2' variant="secondary" onClick={handleRenew}> Sigortayı Yenile </Button>
        <Button className='py-3 py-md-2' variant="danger" onClick={e => setShowDeleteModal(true)}> Sigortayı Sil </Button>
      </div>


      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
      >
        <Modal.Header>
          <Modal.Title> Müşteriyi Sil </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p> <b>{data?.carInsurance.title} </b> adlı sigortayı silmek için KALIN YAZILDIĞ ŞEKİLDE (büyük küçük harf duyarlı)
            yazınız.
          </p>
          <p> Silme işlemi bu sigortaya belgesini kaldırır. Müşteriye veya araca dair veriler korunmaya devam eder. Bu işlem geri alınamaz.</p>
          <Form.Control type="text" value={modalInput} onChange={e => setModalInput(e.target.value)} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}> İptal </Button>
          <Button variant="danger" disabled={data?.carInsurance.title !== modalInput}
            onClick={() => removeCarInsuranceMut({ id: parseInt(data!.carInsurance.id) })}
          > Sigortayı Sil </Button>
        </Modal.Footer>
      </Modal>

    </>
  )
}


export default CarInsuranceView;