import { format } from 'date-fns';
import React, { FormEvent, useState } from 'react';
import { Breadcrumb, Button, Form } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { InsuranceState, useUpdateCarInsuranceMutation } from '../../generated/graphql';
import graphqlRequestClient from '../../Helpers/graphql-client';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import { useAuth } from '../../Helpers/authContext';
import urls from '../../Helpers/urls';

const options = [
  { value: 'UNKNOWN', label: 'Bilinmiyor' },
  { value: 'US', label: 'Bizde' },
  { value: 'RIVAL', label: 'Rakipte' }
]

const CarInsurancesUpdate = () => {

  const { acceptedFiles, getRootProps, open, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'application/pdf': ['.pdf']
    },
    noClick: true,
    noKeyboard: true,
  })

  const files = acceptedFiles.map(file => (
    <li key={file.name}>
      {file.name}
    </li>
  ));

  const navigate = useNavigate();
  const { state }: any = useLocation();
  const { user } = useAuth();
  const [data, setData] = useState({
    title: state.insurance.title,
    state: state.insurance.state,
    startTime: format(new Date(state.insurance.startTime), 'yyyy-MM-dd'),
    endTime: format(new Date(state.insurance.endTime), 'yyyy-MM-dd')
  });


  const { mutate: updateCarInsuranceMut } = useUpdateCarInsuranceMutation(graphqlRequestClient, {
    onSuccess: async () => {

      const formData = new FormData()
      formData.append('file', new Blob([await acceptedFiles[0].arrayBuffer()], { type: 'application/pdf' }));
      fetch(urls.car_pdf_upload(state.insurance.id), {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + user.token,
        },
        body: formData

      }).then(res => res.json())
        .then(json => {
          if (json.error) {
            toast.error(json.msg)
          } else {
            toast.success(json.msg)
            navigate('/app/car-insurances/' + state.insurance.id);
          }
        })
        .catch(err => console.error(err))

    }
  })

  const handleDownloadPdf = () => {
    fetch(urls.car_pdf_get(state.insurance.id), {
      mode: 'cors',
      headers: [
        ['Authorization', 'Bearer ' + user.token],
        ['Content-Type', 'application/pdf']
      ]
    }).then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.target = 'police.pdf'
        link.setAttribute(
          'download',
          `police.pdf`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        //@ts-ignore
        link.parentNode.removeChild(link);
      });

  }


  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    updateCarInsuranceMut({
      id: parseInt(state.insurance.id),
      input: {
        title: {
          set: data.title
        },
        state: {
          set: data.state == 'UNKNOWN' ? InsuranceState.Unknown : data.state == 'US' ? InsuranceState.Us : InsuranceState.Rival,
        },
        startTime: {
          set: data.startTime
        },
        endTime: {
          set: data.endTime
        }
      }
    })
  }


  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to={'/app/contacts'}>Müşteriler</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to={'/app/contacts/' + state?.insurance?.car?.contact?.id}>{state?.insurance?.car?.contact?.name + ' ' + state?.insurance?.car?.contact?.surname}</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to={'/app/properties/cars/' + state?.insurance?.car?.id}>{state?.insurance?.car?.plate}</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to={'/app/car-insurances/' + state?.insurance?.id}>{state?.insurance?.title}</Link></Breadcrumb.Item>
        <Breadcrumb.Item active> Düzenle</Breadcrumb.Item>
      </Breadcrumb>
      <p>Sigorta Düzenle</p>
      <h2 className='display-2 text-center text-md-start'> {state?.insurance?.car?.plate}: {state?.insurance?.title} </h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label> Sigorta Adı </Form.Label>
          <Form.Control size='lg' type="text" value={data.title} onChange={e => setData({ ...data, title: e.target.value })} />
        </Form.Group>
        <Form.Group>
          <Form.Label> Araç </Form.Label>
          <Form.Control size='lg' disabled value={state.insurance.car.plate} />
        </Form.Group>
        <Form.Group>
          <Form.Label> Durum </Form.Label>
          <Select options={options} defaultValue={options[0]} onChange={option => setData({ ...data, state: option!.value })} />
        </Form.Group>
        <Form.Group>
          <Form.Label> Müşteri </Form.Label>
          <Form.Control size='lg' disabled value={state.insurance.car.contact.name + ' ' + state.insurance.car.contact.surname} />
        </Form.Group>
        <Form.Group>
          <Form.Label> Başlangıç Tarihi </Form.Label>
          <Form.Control size='lg'
            type="date"
            value={data.startTime}
            onChange={e => setData({ ...data, startTime: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label> Bitiş Tarihi </Form.Label>
          <Form.Control size='lg'
            type="date"
            value={data.endTime}
            onChange={e => setData({ ...data, endTime: e.target.value })}
          />
        </Form.Group>

        <div className='mt-4'>
          Güncellemeden önceki pdf: {state.insurance.pdf_url ? <button onClick={handleDownloadPdf}><i className="fa-solid fa-file-pdf fs-1"></i></button> : '** PDF Yok **'}
        </div>

        <section className="border-2 p-2 mb-4">
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <p>PDF'leri sürükleyin veya seçmek için gözatın.</p>
            <Button onClick={open} variant='primary'> Gözat... </Button>
          </div>
          <aside>
            {files.length > 0 ?
              <><h6>Dosya:</h6>
                <ul>{files}</ul></>
              : null}
          </aside>
        </section>

        <div className='d-grid mt-2 d-md-block'>
          <Button className='py-3 py-md-2' variant="success" type="submit" > Sigortayı Güncelle </Button>
        </div>
      </Form>

    </>
  )

}


export default CarInsurancesUpdate;